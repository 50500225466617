import React, { FC, useCallback, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Button, Checkbox, Flex } from "antd";
import API from "Api";
import cx from "classnames";
import { SentenceTaskProps } from "Components/SentenceTask/SentenceTask.type";
import styles from "Components/SentenceTask/SentenceTask.module.scss";
import multiSelectStyles from "Components/SentenceTask/MultiSelectTask.module.scss";
import { shuffle } from "lodash";
import { isMobile } from "react-device-detect";
import TaskPanel from "Components/TaskPanel";

const FeedbackMultiTask: FC<SentenceTaskProps> = ({
  lesson,
  sentence: { id, translate, text, alternatives, markers, optionStory, options, description, storyId },
  onTaskComplete,
  onNext,
  children,
  noTranslate,
  noText,
  showGrammar,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedOptions, setSelectedOptions] = useState<{ text: string; wrong: boolean }[]>([]);

  const selectOptions = useMemo<{ text: string; wrong: boolean }[]>(() => {
    const items = [
      ...(optionStory?.sentences.map((s) => ({ text: s.text, wrong: !options?.includes(s.id) })) || []),
      ...alternatives.filter((alt) => alt.text && !alt.media).map((alt) => ({ text: alt.text, wrong: alt.wrong ?? false })),
    ];
    return markers.includes("random") ? shuffle(items) : items;
  }, [alternatives, markers, optionStory?.sentences, options]);

  const onComplete = useCallback(() => {
    setStatus(StatusType.Completed);
    onTaskComplete();
  }, [onTaskComplete]);

  const onSubmit = useCallback(() => {
    API.feedback
      .save({
        answer: selectedOptions.map((opt) => opt.text).join(" ; "),
        question: text || translate || description || "",
        lessonId: lesson.id,
        sentenceId: id,
        type: "taskFeedback",
        task: "multi",
      })
      .then(onComplete);
  }, [selectedOptions, text, translate, description, lesson.id, id, onComplete]);

  const onOptionClick = (opt: { text: string; wrong: boolean }) => () => {
    setStatus(StatusType.Empty);

    if (selectedOptions.includes(opt)) {
      setSelectedOptions((prev) => prev.filter((el) => el !== opt));
    } else {
      setSelectedOptions((prev) => [...prev, opt]);
    }
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <div className={styles.children}>{children}</div>

        {(text || translate) && (
          <div className={cx(styles.slate, styles.slate_alignCenter)}>
            <div className={styles.slate_wrapper}>
              {((noText && status === StatusType.Completed) || !noText) && <div className={cx(styles.textArea, styles.text)}>{text}</div>}
              {((noTranslate && status === StatusType.Completed) || !noTranslate) && <div className={styles.translate}>{translate}</div>}
            </div>
          </div>
        )}

        <div
          className={cx(multiSelectStyles.multiSelectTask, {
            [multiSelectStyles.multiSelectTask__completed]: status === StatusType.Completed,
          })}
        >
          <Flex gap={10} wrap={"wrap"} justify={"center"}>
            {selectOptions?.map((opt) => (
              <Checkbox
                className={cx(multiSelectStyles.option, {
                  [multiSelectStyles.option__mobile]: isMobile,
                  [multiSelectStyles.option__selected]: selectedOptions.includes(opt),
                  [multiSelectStyles.option__right]:
                    [StatusType.Error, StatusType.Completed].includes(status) && !opt.wrong && selectedOptions.includes(opt),
                  [multiSelectStyles.option__wrong]: status === StatusType.Completed && opt.wrong,
                })}
                key={opt.text}
                onChange={onOptionClick(opt)}
                checked={selectedOptions.includes(opt)}
              >
                <div>{opt.text}</div>
              </Checkbox>
            ))}
          </Flex>
        </div>
        {isMobile && <div className={styles.placeholder} />}
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.FeedbackMultiple}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
      >
        <Button type={"primary"} shape={"round"} onClick={onSubmit}>
          ответить
        </Button>
      </TaskPanel>
    </div>
  );
};

export default FeedbackMultiTask;
