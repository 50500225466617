import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";

import { isNumber } from "lodash";

export const smiledIcons: Record<number, any> = {
  1: FrownOutlined,
  2: FrownOutlined,
  3: MehOutlined,
  4: SmileOutlined,
  5: SmileOutlined,
};

export const getStepColor = (percent?: number) => {
  if (percent === 100) return "green";
  if (isNumber(percent) || percent === null) {
    return undefined;
  }
  return "grey";
};
