import { Tooltip } from "antd";
import React, { FC, useEffect, useState } from "react";
import cx from "classnames";
import { POS_TAGS, SkipTags } from "App.constants";
import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { delay } from "lodash";
import styles from "./WordWithTooltip.module.scss";

const WordWithTooltip: FC<{
  word: string;
  isActive: boolean;
  pos: string;
  lemma: string;
  phrase: string;
  show?: Function;
  hide?: Function;
}> = ({ word, isActive, pos, lemma, phrase, show, hide }) => {
  const [open, setOpen] = useState(false);

  const toDict = phrase || (["was", "were", "is", "are", "am"].includes(word) ? word : lemma);

  const { data: dict } = useQuery({
    queryKey: ["word", word, phrase ? undefined : POS_TAGS[pos] || ""],
    queryFn: () => API.dictionary.findOne(toDict, phrase ? undefined : POS_TAGS[pos] || ""),
    staleTime: Infinity,
    enabled: !SkipTags.includes(toDict) && !!toDict && open,
    select: (data) => data?.translates?.join(", ") || "",
  });

  useEffect(() => {
    if (open) {
      delay(() => {
        hide?.();
        setOpen(false);
      }, 3000);
    }
  }, [open, hide]);

  if (SkipTags.includes(word) || word === " ") {
    return <span className={cx(styles.word, { [styles.word_active]: isActive })}>{word}</span>;
  }

  return (
    <Tooltip
      open={open && !!dict}
      trigger={"click"}
      title={dict || " "}
      onOpenChange={(v) => {
        setOpen(v);
        if (v) show?.();
      }}
    >
      <span className={cx(styles.word, { [styles.word_active]: isActive })}>{word}</span>
    </Tooltip>
  );
};

export default WordWithTooltip;
