import { useCallback, useEffect } from "react";
import { delay, findIndex, last } from "lodash";
import { SentenceType } from "App.types";

type Props = {
  audio: HTMLAudioElement;
  sentences: SentenceType[] | undefined;
  setActiveSent: Function;
  setActiveWordIdx: Function;
};

export const usePlaySentence = ({ audio, sentences = [], setActiveSent, setActiveWordIdx }: Props) => {
  useEffect(() => {
    if (!audio) return;

    const listener = ({ target: { paused, currentTime } }: any) => {
      if (paused) {
        return delay(() => {
          setActiveSent(undefined);
          setActiveWordIdx(null);
        }, 500);
      }
      let activeSent = sentences.find(
        ({ transcripts }) => transcripts[0]?.start <= currentTime && (last(transcripts)?.end || 0) >= currentTime,
      );
      if (!activeSent) {
        activeSent = sentences.find(({ transcripts }) => transcripts[0]?.start >= currentTime);
      }

      if (activeSent) {
        setActiveSent(activeSent.id);

        const wordIdx = findIndex(activeSent.transcripts, (el) => el.end >= currentTime);
        setActiveWordIdx(wordIdx > -1 ? wordIdx : null);
      }
    };
    audio.addEventListener("timeupdate", listener);

    return () => audio.removeEventListener("timeupdate", listener);
  }, [audio, sentences, setActiveSent, setActiveWordIdx]);

  return useCallback(
    (from: number, to: number) => {
      if (!audio) return;

      audio.currentTime = from;

      audio.ontimeupdate = ({ target: { currentTime } }: any) => {
        if (currentTime >= to) {
          // audio.removeEventListener("timeupdate", ontimeupdate);
          audio.pause();
        }
      };

      //audio.addEventListener("timeupdate", ontimeupdate);

      audio.play();
    },
    [audio],
  );
};
