import { LessonType, SentenceType } from "App.types";
import React, { FC } from "react";
import { Button, Collapse, Popover, Typography, Tabs, Flex, Space } from "antd";
import { SoundTwoTone } from "@ant-design/icons";
import cx from "classnames";
import styles from "./StorySentence.module.scss";

const { Text } = Typography;

const StorySentenceDictionary: FC<{
  sentence: SentenceType;
  active?: boolean;
  play?: Function;
  marginLeft?: boolean;
  onClick?: () => any;
  storyId?: string | number;
  audio?: HTMLAudioElement;
  lesson?: LessonType;
}> = ({ audio, marginLeft, active, onClick, sentence: { translate, text, grammarLinks = [] } }) => {
  const onPlay = () => {
    audio?.play();
    // play(transcripts[0].start, last(transcripts)?.end, true);
  };

  const items = grammarLinks.length
    ? [
        {
          label: "грамматика",
          children: (
            <Tabs
              size={"small"}
              items={grammarLinks.map((gram) => ({
                key: `${gram.id}`,
                label: gram.grammar.title,
                children: <div dangerouslySetInnerHTML={{ __html: gram.grammar.content }} />,
              }))}
            />
          ),
        },
      ]
    : [];

  return (
    <Popover
      content={
        <div className={styles.storySent}>
          <span>
            <Text style={{ fontSize: 18, fontWeight: 500 }}>{text}</Text>
          </span>
          <Space>
            <Button size={"middle"} onClick={onPlay} icon={<SoundTwoTone />} type={"text"} />

            <Flex align={"center"}>
              <Collapse ghost accordion size={"small"} items={items} />
            </Flex>
          </Space>
        </div>
      }
      trigger={"click"}
    >
      <span
        onClick={onClick}
        className={cx(styles.storySent__text, styles.storySent__textDict, {
          [styles.storySent__textWrap]: marginLeft,
          [styles.storySent__textActive]: active,
        })}
      >
        {text} - <span className={styles.storySent__translate}> {translate}</span>
      </span>
      {/*<Dictionary storyId={storyId} isOpen={isDictOpen} toggle={setDictOpen} sentences={sentence} />*/}
    </Popover>
  );
};
export default StorySentenceDictionary;
