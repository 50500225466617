import { Editable, ReactEditor, Slate } from "slate-react";
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { SentenceType, StatusType } from "App.types";
import { Button } from "antd";
import cx from "classnames";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "Components/SentenceTask/SpeechLessonTask.module.scss";
import { useAudioTranscript } from "Components/SentenceTask/Helpers/useAudioTranscript";
import SentenceLeaf from "Components/SentenceTask/SentenceLeaf";
import { useTranscripts } from "Hooks/useTranscripts";

const initialValue = [{ children: [{ text: "" }] }];

type Props = PropsWithChildren & {
  sentence: SentenceType;
  // setTries?: Dispatch<SetStateAction<number>>;
  audio?: HTMLAudioElement;
  showAnswer?: boolean;
  autoHeight?: boolean;
  onClear: Function;
  editor: any;
  status: StatusType;
  resetStatus: Function;
};

const IntroLessonTaskTranslate: FC<Props> = ({
  sentence,
  sentence: { translate },
  children,
  audio,
  onClear,
  editor,
  status,
  resetStatus,
}) => {
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  useEffect(() => {
    try {
      ReactEditor.focus(editor);
    } catch (e) {}
  }, [editor, sentence]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    // setStatus(StatusType.Empty);
  }, [sentence.id]);

  const transcripts = useTranscripts({ sentence });

  // audio transcript
  useAudioTranscript({ transcripts, setActiveLeaf, sentence, audio });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceLeaf
        underlined={props.leaf.audioIdx === activeLeaf}
        showErrors={![StatusType.Completed, StatusType.Editing].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  // console.log(text, editor.children[0]);

  return (
    <div className={cx(styles.speechLessonTask, { [styles.speechLessonTask__inActive]: false })}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: true })}>
          <div className={styles.slate_wrapper}>
            <Slate editor={editor} initialValue={initialValue}>
              <Editable
                onKeyDown={() => resetStatus()}
                className={styles.textArea}
                renderLeaf={renderLeaf}
                readOnly={status === StatusType.Completed}
              />
            </Slate>

            {status === StatusType.Completed ? (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CheckOutlined style={{ fontSize: 20, color: "green" }} />}
              />
            ) : (
              <Button onClick={() => onClear()} type={"text"} className={styles.btn__clear} icon={<CloseOutlined />} />
            )}
          </div>
          <div className={styles.translate}>{translate}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IntroLessonTaskTranslate);
