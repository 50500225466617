import { Breadcrumb, Button, Table } from "antd";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { ColumnsType } from "antd/es/table";
import { VocabularyType } from "App.types";
import { HomeOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonClose from "Components/ButtonClose";
import styles from "./Vocabulary.module.scss";
import dayjs from "dayjs";
import { SkipTags } from "App.constants";
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const Vocabulary = () => {
  const { data } = useSuspenseQuery({
    queryKey: ["vocabulary"],
    queryFn: API.progress.getVocabulary,
    select: (data) =>
      data
        .filter((r) => !SkipTags.includes(r.word))
        .map((row) => ({
          ...row,
          // @ts-ignore
          fromTime: dayjs(row.timestamp).fromNow(),
        })),
  });

  const navigate = useNavigate();

  const columns: ColumnsType<VocabularyType> = [
    {
      title: "word",
      dataIndex: "word",
    },
    {
      title: "last exercise",
      dataIndex: "fromTime",
    },
  ];

  return (
    <div className={styles.vocabulary}>
      <Breadcrumb
        items={[
          { title: <Button type={"link"} onClick={() => navigate("/")} size={"small"} icon={<HomeOutlined />}></Button> },
          { title: "Vocabulary" },
        ]}
      />
      <ButtonClose className={styles.btnClose} />
      <Table dataSource={data} columns={columns} size={"small"} pagination={false} />
    </div>
  );
};

export default Vocabulary;
