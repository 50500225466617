import { useNavigate, useSearchParams } from "react-router-dom";
import { FC, useEffect } from "react";
import axios, { AxiosInstance } from "axios";
import { API_URL } from "App.constants";
import Cookies from "js-cookie";
import { useQueryClient } from "@tanstack/react-query";

const GoogleToken: FC<{ axiosInstance: AxiosInstance; setUser: Function }> = ({ setUser, axiosInstance }) => {
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      axios
        .get(`${API_URL}/users/afterLogin`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: false })
        .then(({ data }) => {
          axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;

          Cookies.set("token", token, { expires: 24 * 60 * 60 * 31 });
          Cookies.set("user", JSON.stringify({ ...data, isEnglish: data.lang === "en" }), { expires: 24 * 60 * 60 * 31 });

          setUser({ ...data, isEnglish: data.lang === "en" });

          queryClient.setDefaultOptions({ queries: { enabled: true } });
          queryClient.resetQueries({ fetchStatus: "idle" }).then();

          navigate("/");
        });
    }
  }, [axiosInstance.defaults.headers, navigate, queryClient, searchParams, setUser]);

  return null;
};

export default GoogleToken;
