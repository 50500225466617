import { Button } from "antd";
import { DOMAttributes, FC } from "react";
import { AudioOutlined, MessageOutlined } from "@ant-design/icons";
import styles from "./RecordButton.module.scss";

const RecordButton: FC<
  {
    onClick?: () => any;
    disabled?: boolean;
    isRecording?: boolean;
    loading?: boolean;
    isSpeaking?: boolean;
  } & DOMAttributes<HTMLButtonElement>
> = ({ disabled, isRecording, loading, onClick, isSpeaking, ...rest }) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    className={styles.recordButton}
    type={"primary"}
    icon={isSpeaking ? <MessageOutlined /> : <AudioOutlined />}
    shape="circle"
    data-recording={isRecording}
    loading={loading}
    data-speaking={isSpeaking}
    {...rest}
  />
);

export default RecordButton;
