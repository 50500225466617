import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CustomText, LessonType, SentenceType, StatusType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button, Popconfirm, Space, Tag, Tooltip } from "antd";
import API from "Api";
import { delay } from "lodash";
import cx from "classnames";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { BulbOutlined, SendOutlined } from "@ant-design/icons";
import SentenceMaskedLeaf from "../SentenceLeaf";
import { useOnCheck } from "../Helpers/useOnCheck";
import { usePressEnter } from "../Helpers/usePressEnter";
import styles from "./DialogTaskWriting.module.scss";
import { isMobile } from "react-device-detect";

const initialValue = [{ children: [{ text: " " }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const DialogTaskWriting: FC<{ sentence: SentenceType; lesson: LessonType; onTaskComplete: Function }> = ({
  sentence,
  sentence: { id, text, tags, translate, alternatives, markers, phrases, isLeft },
  onTaskComplete,
  lesson,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [hints, setHints] = useState<string[]>([]);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const noTranslate = markers.includes("noTranslate");

  const isCompleted = status === StatusType.Completed;

  const setInitialState = useCallback(
    (withFocus: boolean = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, initialValue, { select: withFocus });
      if (withFocus) {
        try {
          ReactEditor.focus(editor);
        } catch (e) {}
      }
    },
    [editor],
  );

  // const setCompletedState = useCompletedSlate({ tags, text, editor });

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState(true);
    const hintTags = tags.filter((t, idx) => t.isHint && !phrases.some((ph) => ph.positionsIdx.includes(idx))).map((t) => t.lemma);
    const hintPhrases = phrases.filter((ph) => ph.positionsIdx.some((idx) => tags[idx].isHint)).map((ph) => ph.text);

    setHints([...hintTags, ...hintPhrases]);
  }, [sentence.id, setInitialState, tags, phrases]);

  // audio transcript
  // useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onComplete = useCallback(
    (showMessage?: boolean, answer?: string) => {
      console.log(answer);
      setStatus(StatusType.Completed);
      onTaskComplete();
    },
    [onTaskComplete],
  );

  const onCheck = useOnCheck({
    editor,
    activeType: TaskType.Translate,
    text,
    tags,
    id,
    lesson,
    alternatives,
    onComplete,
    setStatus,
  });

  const onHintAnswer = async () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join("");
    API.event.save({ text, type: "hint", task: TaskType.Translate, lesson: { id: lesson.id }, sentence });

    const status = await onCheck(text, true);
    const wrongIdx = editor.children[0]?.children.findIndex((child: CustomText) => child.status === "wrong");
    if (wrongIdx >= 0) {
      Transforms.insertText(editor, "", { at: [0, wrongIdx] });
      await onCheck("", true);
    }

    if (status) {
      const hasError = editor.children[0]?.children.every((el: CustomText, idx: number) => {
        if (["missed", "lemma", "typo"].includes(el.status || "")) {
          //  setTry(true);
          Transforms.setNodes(editor, { status: "success" }, { at: [0, idx] });
          Transforms.insertText(editor, el.word || el.answer || "", { at: [0, idx] });
          setActiveLeaf(el.idx || null);
          delay(() => setActiveLeaf(null), 2000);
          return false;
        }

        if (el.status === "order") {
          editor.children.forEach(() => {
            Transforms.delete(editor, { at: [0] });
          });

          editor.children = [];
          Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
          return false;
        }
        return true;
      });

      if (hasError) {
        // setTry();
      }

      try {
        ReactEditor.focus(editor);
      } catch (e) {}
    }
  };

  usePressEnter({ isCompleted, onCheck });

  return (
    <div className={styles.dialogTaskWriting}>
      <div className={cx(styles.slate)}>
        <Tooltip title={status === StatusType.Completed ? translate : ""}>
          <div
            className={cx(styles.slate_wrapper, {
              [styles.slate_wrapper__left]: isLeft,
              [styles.slate_wrapper__completed]: status === StatusType.Completed,
            })}
          >
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={isCompleted}
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>
            {!isCompleted && (
              <Button onClick={() => onCheck()} size={"middle"} className={cx(styles.btnHint)} type={"text"} icon={<SendOutlined />} />
            )}

            {!isCompleted && (
              <Popconfirm title={"Дать подсказку?"} onConfirm={onHintAnswer}>
                <Button
                  style={{ bottom: isMobile ? 4 : 0 }}
                  size={isMobile ? "middle" : undefined}
                  type={"text"}
                  icon={<BulbOutlined />}
                  className={cx(styles.bulb, { [styles.bulb__right]: !isLeft })}
                />
              </Popconfirm>
            )}
          </div>
        </Tooltip>

        {!noTranslate && !isCompleted && <div className={styles.translate}>{translate}</div>}
      </div>

      <div className={styles.bottom}>
        {!isCompleted && (
          <Space>
            {hints.map((hint) => (
              <Tag style={{ fontSize: 16 }} bordered={false} key={hint}>
                {hint}
              </Tag>
            ))}
          </Space>
        )}
      </div>
    </div>
  );
};

export default DialogTaskWriting;
