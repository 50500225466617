import { useContext, useMemo } from "react";
import { flatten, shuffle, uniq } from "lodash";
import { SentenceType, TaskType, UserProp } from "App.types";
import { STORY_TASKS } from "App.constants";
import { replaceTemplates } from "App.helpers";
import API from "Api";
import { useSuspenseQuery } from "@tanstack/react-query";
import { UserContext } from "App";

export const GetTasksForSentences = (sentences: SentenceType[], userProps: UserProp[]) => {
  const sentWithTasks = sentences.filter((s) => s.tasks.length && s.tasks[0] !== TaskType.Intro);

  return flatten(
    sentWithTasks.map((sent) => {
      if (sent.linkedStory) {
        const words = uniq(sent.linkedStory?.sentences.filter((t) => !t.linkedStoryId && t.text).map((s) => s.text));
        const translates = uniq(sent.linkedStory?.sentences.filter((t) => !t.linkedStoryId && t.translate).map((s) => s.translate));

        if (sent.tasks[0] === TaskType.FromStory) {
          const sentences = flatten(
            sent.linkedStory.sentences.map((s) =>
              s.tasks.map((task) => ({
                ...s,
                task,
                markers: [...s.markers, ...sent.markers],
                alternatives: s.alternatives.map((alt) => ({
                  ...alt,
                  text: replaceTemplates(alt.text, userProps),
                  translate: replaceTemplates(alt.translate, userProps),
                })),
                text: replaceTemplates(s.text, userProps) ?? "",
                translate: replaceTemplates(s.translate, userProps) ?? "",
                description: replaceTemplates(s.description, userProps),
                tags: s.tags.map((tag) => ({ ...tag, word: replaceTemplates(tag.word, userProps) })),
                lessonOrder: sent.order,
                wordGroup: s.wordGroup || { id: 0, title: "from sentences", words },
                reverseGroup: s.reverseGroup || { id: 0, title: "from sentences", words: translates },
              })),
            ),
          );

          return sent.markers.includes("random") ? shuffle(sentences) : sentences;
        }

        const items = sent.tasks.some((task) =>
          [
            TaskType.Intro,
            TaskType.StorySelect,
            TaskType.ActiveDialog,
            TaskType.Scroller,
            TaskType.Video,
            TaskType.VideoDragDrop,
            ...STORY_TASKS,
          ].includes(task),
        )
          ? sent.tasks.map((task) => {
              return [
                TaskType.Intro,
                TaskType.StorySelect,
                TaskType.ActiveDialog,
                TaskType.Scroller,
                TaskType.Video,
                TaskType.VideoDragDrop,
                ...STORY_TASKS,
              ].includes(task)
                ? [
                    {
                      ...sent,
                      task,
                      story: sent.linkedStory,
                      lessonOrder: sent.order,
                      description: replaceTemplates(sent.description, userProps),
                    },
                  ]
                : sent.linkedStory?.sentences.map((s) => ({
                    ...s,
                    task,
                    alternatives: s.alternatives.map((alt) => ({
                      ...alt,
                      text: replaceTemplates(alt.text, userProps),
                      translate: replaceTemplates(alt.translate, userProps),
                    })),
                    text: replaceTemplates(s.text, userProps) ?? "",
                    translate: replaceTemplates(s.translate, userProps) ?? "",
                    description: replaceTemplates(s.description, userProps),
                    tags: s.tags.map((tag) => ({ ...tag, word: replaceTemplates(tag.word, userProps) })),
                    wordGroup: s.wordGroup || { id: 0, title: "from sentences", words },
                    reverseGroup: s.reverseGroup || { id: 0, title: "from sentences", words: translates },
                    lessonOrder: sent.order,
                  }));
            })
          : sent.linkedStory?.sentences.map((s) =>
              sent.tasks.map((task) => ({
                ...s,
                task,
                alternatives: s.alternatives.map((alt) => ({
                  ...alt,
                  text: replaceTemplates(alt.text, userProps),
                  translate: replaceTemplates(alt.translate, userProps),
                })),
                text: replaceTemplates(s.text, userProps) ?? "",
                translate: replaceTemplates(s.translate, userProps) ?? "",
                description: replaceTemplates(s.description, userProps),
                tags: s.tags.map((tag) => ({ ...tag, word: replaceTemplates(tag.word, userProps) })),
                wordGroup: s.wordGroup || { id: 0, title: "from sentences", words },
                reverseGroup: s.reverseGroup || { id: 0, title: "from sentences", words: translates },
                lessonOrder: sent.order,
              })),
            );

        // @ts-ignore
        return sent.markers.includes("random") ? shuffle(flatten(items)) : flatten(items);
      } else {
        const items = sent.tasks.map((task) => ({
          ...sent,
          task,
          alternatives: sent.alternatives.map((alt) => ({
            ...alt,
            text: replaceTemplates(alt.text, userProps),
            translate: replaceTemplates(alt.translate, userProps),
          })),
          description: replaceTemplates(sent.description, userProps),
          text: replaceTemplates(sent.text, userProps) ?? "",
          translate: replaceTemplates(sent.translate, userProps) ?? "",
          tags: sent.tags.map((tag) => ({
            ...tag,
            word: replaceTemplates(tag.word, userProps),
            lemma: replaceTemplates(tag.word, userProps),
          })),
          lessonOrder: sent.order,
        }));
        return sent.markers.includes("random") ? shuffle(items) : items;
      }
    }),
  );
};

export const useAllTasks = (sentences: SentenceType[], existingTasks?: any[]) => {
  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  return useMemo(() => {
    if (existingTasks) return existingTasks;

    return GetTasksForSentences(sentences, userProps);
  }, [existingTasks, sentences, userProps]);
};
