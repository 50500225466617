import { isEqualEnglishWords } from "App.helpers";
import { TagType } from "App.types";
import { SkipTags } from "App.constants";

export const compareTextByTags = (tagsA: TagType[] = [], tagsB: TagType[] = []) => {
  const filteredTagsA = tagsA.filter((tag) => !SkipTags.includes(tag.word));
  const filteredTagsB = tagsB.filter((tag) => !SkipTags.includes(tag.word));

  return filteredTagsA.every((tag, idx) => isEqualEnglishWords(tag.word, filteredTagsB[idx]?.word));
};

export const arrayMove = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  const newArr = [...arr];
  newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
  return newArr;
};
