// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCMEELezk64a7weYYyqr5ha8rVNXsCnaOQ",
  authDomain: "my.lexoid.ru",
  databaseURL: "https://lexoid21.firebaseio.com",
  projectId: "lexoid21",
  storageBucket: "lexoid21.appspot.com",
  messagingSenderId: "63145564413",
  appId: "1:63145564413:web:b25b8a5e661e5dc65f4694",
  measurementId: "G-QPCRDQRPSJ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
