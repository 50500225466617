import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { successMessage } from "App.constants";
import { Button, notification } from "antd";
import API from "Api";
import { isNumber, shuffle } from "lodash";
import { SoundOutlined, SwapLeftOutlined } from "@ant-design/icons";
import { usePressEnter } from "./Helpers/usePressEnter";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import styles from "./SentenceTask.module.scss";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { arrayMove } from "./SentenceTask.helpers";
import { isMobile } from "react-device-detect";
import TaskPanel from "Components/TaskPanel";

const SpellerCollect: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { text, translate, id, storyId },
  lesson,
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  showSuccessMessage = true,
  setTry,
  transcripts,
  showGrammar,
  setDictOpened,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [focused, setFocused] = useState(true);
  const [, setActiveLeaf] = useState<number | null>(null);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [wrongTags, setWrongTags] = useState<number[]>([]);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const shuffledTags = useMemo(() => shuffle([...text].map((l, index) => ({ word: l, idx: index }))), [text]);

  const tagsToSelect = useMemo(() => shuffledTags.filter((el) => !selectedTags.includes(el.idx)), [shuffledTags, selectedTags]);

  // initial
  useEffect(() => {
    setSelectedTags([]);
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
  }, [sentence.id]);

  // audio transcript
  useAudioTranscript({ audio, sentence, setActiveLeaf, transcripts });

  const onCheck = useCallback(
    (answer?: number[], isHintClicked: boolean = false): Promise<number[]> => {
      const selected = answer || selectedTags;

      const wrongs: number[] = [];
      [...text].forEach((l, index) => {
        if (text[selected[index]] !== l) {
          wrongs.push(index);
        }
      });

      if (!wrongs.length) {
        setStatus(StatusType.Completed);
        showSuccessMessage && notifyApi.success(successMessage);
        onTaskComplete();
        // setSelectedTags(tags.map((t) => t.id));
        return Promise.resolve([]);
      } else {
        setWrongTags([...wrongs]);
      }

      if (!isHintClicked)
        notifyApi.warning({
          message: "Есть ошибки",
          duration: 2,
        });

      setStatus(StatusType.Order);

      if (selectedTags.length && !isHintClicked) {
        API.event.save({
          sentence: { id: sentence.id },
          text: selectedTags.map((idx) => text[idx]).join(" "),
          type: "mistake",
          task: TaskType.Collect,
          lesson: { id: lesson.id },
        });
      }
      return Promise.reject(wrongs);
    },
    [lesson.id, notifyApi, onTaskComplete, selectedTags, sentence.id, showSuccessMessage, text],
  );

  const onHintAnswer = () => {
    const userWord = selectedTags.map((idx) => text[idx]).join(" ") as string;
    API.event.save({ text: userWord, type: "hint", task: TaskType.Collect, lesson: { id: lesson.id }, sentence: { id: sentence.id } });

    onCheck(undefined, true).catch((wrongs) => {
      setTry(true);
      const wrongIdx = wrongs[0];
      const wrongIdxs = wrongs.map((n: number) => selectedTags[n]);
      const rightIdx = wrongs.find((wIdx: number) => text[wIdx] === text[wrongIdx]);
      const fromUnselected = tagsToSelect.find((t) => text[wrongIdx] === t.word)?.idx;

      setSelectedTags((prev) => {
        if (!isNumber(fromUnselected)) {
          const from = prev.findIndex((t) => text[t] === text[wrongIdx] && wrongIdxs.includes(t));
          let newAr = arrayMove(prev, from, wrongIdx);

          if (from !== wrongIdx + 1) newAr = arrayMove(newAr, wrongIdx + 1, from);

          const wrongIndex = newAr.findIndex((n, idx) => text[n] !== text[idx]);
          return wrongIndex > 0 ? newAr.slice(0, wrongIndex) : newAr;
        } else {
          // prev.splice(index, 0, index);
          prev.splice(rightIdx, 0, fromUnselected);
          const wrongIndex = prev.findIndex((n, idx) => text[n] !== text[idx]);
          return wrongIndex > 0 ? prev.slice(0, wrongIndex) : [...prev];
        }
      });
    });

    setWrongTags([]);
  };

  usePressEnter({ isCompleted: status === StatusType.Completed, onNext, onCheck });

  useEffect(() => {
    setWrongTags([]);
  }, [selectedTags]);

  const handleDragEnd = ({ destination, draggableId, source }: DropResult) => {
    // if (!destination?.droppableId) {
    //   setSelectedTags((prev) => arrayMove(prev, source.index, selectedTags.length));
    // }

    if (source.droppableId === "answer" && destination?.droppableId === "answer") {
      // selectedTags.splice(source.index, 1, selectedTags.splice(destination.index, 1, selectedTags[source.index])[0]);
      setSelectedTags((prev) => arrayMove(prev, source.index, destination.index));
    }

    if (source.droppableId === "options" && destination?.droppableId === "options") {
      setSelectedTags((prev) => [...prev, +draggableId]);
    }

    if (destination?.droppableId === "answer") {
      selectedTags.splice(destination.index, 0, +draggableId);
      setSelectedTags((prev) => [...prev]);
    }
  };

  useEffect(() => {
    if (isMobile) return;
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    const handler = (e: KeyboardEvent) => {
      if (e.key === "Backspace" && status !== StatusType.Completed) {
        return setSelectedTags((prev) => {
          prev.splice(-1);
          return [...prev];
        });
      }
      const tag = tagsToSelect.find((t) => t.word.toLocaleLowerCase() === e.key.toLowerCase());
      if (tag) {
        setSelectedTags((prev) => {
          const next = [...prev, tag?.idx];
          if (tagsToSelect.length === 1) {
            onCheck(next);
          }
          return next;
        });
      }
    };
    window.addEventListener("focus", onFocus);
    window.addEventListener("keydown", handler);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("keydown", handler);
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [onCheck, status, tagsToSelect]);

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={() => setWrongTags([])}>
      <div className={styles.sentenceTask}>
        <div className={cx(styles.content, styles.content_collectTask, { [styles.content_hasChildren]: !!children })}>
          <div className={styles.children}>{children}</div>

          <div className={cx(styles.slate, { [styles.slate_spellerCollect]: !isMobile })}>
            <div className={styles.slate_wrapper}>
              <Droppable droppableId={"answer"} direction={"horizontal"}>
                {(provided, snapshot) => (
                  <div className={cx(styles.textArea, styles.answer)} {...provided.droppableProps} ref={provided.innerRef}>
                    {selectedTags.map((idx, index) => {
                      return (
                        <Draggable key={idx} index={index} draggableId={`${idx}`}>
                          {(draggableProvided, snapshot) => (
                            <span
                              className={cx(styles.tag, styles.tag__merged, {
                                [styles.tag__mobile]: isMobile,
                                [styles.tag__wrong]: wrongTags.includes(index),
                              })}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              onClick={() => setSelectedTags((prev) => prev.filter((t) => t !== idx))}
                            >
                              {text[idx]}
                            </span>
                          )}
                        </Draggable>
                      );
                    })}
                    {status !== StatusType.Completed && !isMobile && focused && <span className={styles.cursor} />}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              {status === "completed" ? (
                <Button
                  size={"small"}
                  type={"text"}
                  className={styles.btn__clear}
                  icon={<SoundOutlined style={{ fontSize: 22 }} />}
                  onClick={() => play?.() || audio?.play()}
                />
              ) : (
                <Button
                  size={"small"}
                  type={"text"}
                  className={styles.btn__clear}
                  icon={<SwapLeftOutlined style={{ fontSize: 24 }} />}
                  onClick={() =>
                    setSelectedTags((prev) => {
                      prev.splice(-1);
                      return [...prev];
                    })
                  }
                />
              )}
            </div>
            <div className={styles.translate}>{translate}</div>
          </div>

          <div className={styles.optionsWrapper}>
            <Droppable droppableId={"options"} isDropDisabled direction={"horizontal"}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={cx(styles.options, { [styles.options__mobile]: isMobile })}
                  // isDraggingOver={snapshot.isDraggingOver}
                >
                  {tagsToSelect.map((l, index) => (
                    <Draggable key={l.idx} index={index} draggableId={`${l.idx}`}>
                      {(provided, snapshot) => (
                        <span
                          onClick={() => setSelectedTags((prev) => [...prev, l.idx])}
                          className={cx(styles.tag, styles.letter, { [styles.tag__mobile]: isMobile })}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {l.word}
                        </span>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>

        <TaskPanel
          lessonId={lesson.id}
          task={TaskType.SpellerCollect}
          sentId={id}
          storyId={storyId}
          onCheck={onCheck}
          onNext={onNext}
          isCompleted={status === StatusType.Completed}
          onHint={onHintAnswer}
          setDictOpened={setDictOpened}
          audio={audio}
          showGrammar={showGrammar}
        ></TaskPanel>

        {contextHolder}
      </div>
    </DragDropContext>
  );
};

export default SpellerCollect;
