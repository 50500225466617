import { Avatar, Dropdown, Layout, notification, Skeleton, Space, Tag, theme, Tooltip } from "antd";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FC, Suspense, useCallback, useEffect, useMemo } from "react";
import { AreaChartOutlined, CrownTwoTone, DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import styles from "./MainLayout.module.scss";
import { googleLogout } from "@react-oauth/google";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Levels } from "App.constants";
import API from "Api";
import { isMobile } from "react-device-detect";
import CountUp from "react-countup";
import { AxiosInstance } from "axios";
import cx from "classnames";
import LoginModal from "./LoginModal";
import { UserType } from "App.types";

const { Header, Content } = Layout;

const MainLayout: FC<{
  axiosInstance: AxiosInstance;
  setUser: Function;
  user: UserType | undefined;
}> = ({ axiosInstance, setUser, user }) => {
  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   if (token && token.length > 100) {
  //     setUser(jwt_decode(token));
  //   } else if (token) {
  //     axios.get("https://login.yandex.ru/info", { params: { oauth_token: token, format: "json" } }).then(({ data }) => {
  //       setUser({ name: data.display_name, picture: `https://avatars.yandex.net/get-yapic/${data.default_avatar_id}/islands-middle` });
  //     });
  //   }
  // }, [token]);

  // const [collapsed, setCollapsed] = useState(false);
  // const [mobileMenu, setMobileMenu] = useState(false);

  const { id, title } = useParams();
  const { pathname } = useLocation();

  const [notifyApi, context] = notification.useNotification({ stack: { threshold: 1 } });

  const showHeader = useMemo(() => !((id || title) && isMobile) && !pathname.includes("/review/"), [id, title, pathname]);

  const openNotification = useCallback(
    (description: string) => {
      notifyApi.destroy("api");
      notifyApi.error({ key: "api", message: "", description });
    },
    [notifyApi],
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // Cookies.remove("token");
        // Cookies.remove("user");
        setUser(undefined);
      } else {
        openNotification(error.message);
      }
      return Promise.reject(error);
    },
  );

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { data: userInfo } = useQuery({ queryKey: ["user", user?.name], queryFn: API.user.get, staleTime: Infinity });

  const { data: wordCount = 0 } = useQuery({
    queryKey: ["wordCount"],
    queryFn: API.progress.getVocabularyCount,
    // enabled: !!user,
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          // Cookies.remove("user");
          // Cookies.remove("token")
          setUser(undefined);
        } else {
          openNotification(error.message);
        }
        return Promise.reject(error);
      },
    );
  }, [user, openNotification, setUser, axiosInstance]);

  const onLogout = () => {
    googleLogout();
    setUser(undefined);
    Cookies.remove("user");
    navigate("/");
  };

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout className={cx(styles.mainLayout, { [styles.mainLayout_fullWidth]: location.pathname.includes("/review/") })}>
      {/*<Drawer className={styles.drawer} open={mobileMenu} placement={"left"} onClose={() => setMobileMenu(false)}>*/}
      {/*  <Menu onSelect={() => setMobileMenu(false)} theme={"light"} mode={"inline"} items={items} />*/}
      {/*</Drawer>*/}

      {!user && <Header className={styles.header} style={{ background: colorBgContainer }}></Header>}

      {!user && <LoginModal axiosInstance={axiosInstance} open={!user} setUser={setUser} />}

      {showHeader && user?.name && (
        <Header className={styles.header} style={{ background: colorBgContainer }}>
          {/*<Button className={styles.header__menuIcon} type="primary" onClick={() => setMobileMenu(!mobileMenu)}>*/}
          {/*  {mobileMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}*/}
          {/*</Button>*/}

          <div className={cx(styles.header__container, { [styles.header__container_mobile]: isMobile })}>
            <div className={styles.user}>
              {/*<Tooltip title={`Текущий уровень: ${Levels[userInfo?.level || 0].title}`} color={"lime"}>*/}
              {/*  <div className={styles.level}>{Levels[userInfo?.level || 0].grade}</div>*/}
              {/*</Tooltip>*/}
              <Avatar
                size={"large"}
                src={
                  user?.picture?.includes("http") ? user?.picture : `https://avatars.yandex.net/get-yapic/${user?.picture}/islands-middle`
                }
              />
              <div className={styles.user_name}>
                <Dropdown
                  menu={{
                    items: [
                      { key: 0, label: "Статистика", icon: <AreaChartOutlined /> },
                      { key: 1, label: "Профиль", icon: <UserOutlined />, onClick: () => navigate("/profile") },
                      { key: 2, label: "Выход", icon: <LogoutOutlined />, onClick: onLogout },
                    ],
                  }}
                >
                  <div>
                    <span>{isMobile ? user?.name?.split("@")?.[0] : user?.name}</span>
                    <DownOutlined />
                  </div>
                </Dropdown>
                <span style={{ fontSize: 12, marginRight: 5 }}>Level:</span>
                <Tag style={{ fontSize: 12 }} color={"green"}>
                  {Levels[userInfo?.level || 0].title}
                </Tag>
              </div>
            </div>

            <div>
              <Tooltip title={"vocabulary"}>
                <Space className={styles.vocabulary} onClick={() => navigate("/vocabulary")}>
                  <CrownTwoTone />
                  <CountUp end={wordCount} />
                </Space>
              </Tooltip>
            </div>
            {/*<Button className={styles.buttonLogout} type={"link"} icon={<LogoutOutlined />} onClick={onLogout}></Button>*/}
          </div>
        </Header>
      )}
      <Content className={styles.container}>
        <div className={cx(styles.content, { [styles.content__withoutHeader]: !showHeader })}>
          {user && (
            <Suspense fallback={<Skeleton active loading />}>
              <Outlet />
            </Suspense>
          )}
        </div>
      </Content>
      {/*<Footer style={{ textAlign: "center" }}>www.lexoid.ru 2023</Footer>*/}
      {context}
    </Layout>
  );
};

export default MainLayout;
