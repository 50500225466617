import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { Navigate } from "react-router-dom";
import { useContext, useMemo } from "react";
import { UserContext } from "App";

const MainPage = () => {
  const { data: courses } = useSuspenseQuery({
    queryKey: ["courses"],
    queryFn: API.course.getList,
    staleTime: Infinity,
  });

  const user = useContext(UserContext);

  const activeCourse = useMemo(() => courses.find((c) => c.id === user?.activeCourse), [courses, user]);

  return <Navigate to={activeCourse ? `/course/${activeCourse?.title}` : "/courses"} />;
};

export default MainPage;
