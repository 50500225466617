import { Drawer } from "antd";
import React, { FC, useContext } from "react";
import { GrammarType } from "App.types";
import styles from "./GrammarPanel.module.scss";
import useGrammarAudio from "Hooks/useGrammarAudio";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { UserContext } from "App";
import { replaceTemplates } from "App.helpers";

const GrammarPanel: FC<{ grammar?: GrammarType; isOpen: boolean; toggle: Function }> = ({ grammar, isOpen, toggle }) => {
  useGrammarAudio(isOpen ? grammar?.content || "" : "");

  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  return (
    <Drawer size={"large"} title={grammar?.title} placement="right" onClose={() => toggle(false)} open={isOpen}>
      <div className={styles.grammarPanel} dangerouslySetInnerHTML={{ __html: replaceTemplates(grammar?.content || "", userProps) }} />
    </Drawer>
  );
};

export default GrammarPanel;
