import React, { FC, useCallback, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { Checkbox, Flex, notification } from "antd";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { successMessage } from "App.constants";
import styles from "./SentenceTask.module.scss";
import multiSelectStyles from "./MultiSelectTask.module.scss";
import { shuffle } from "lodash";
import { isMobile } from "react-device-detect";
import TaskPanel from "Components/TaskPanel";

const MultiSelectTask: FC<SentenceTaskProps> = ({
  sentence: { id, translate, text, alternatives, markers, optionStory, options, storyId },
  onTaskComplete,
  onNext,
  children,
  setDictOpened,
  showGrammar,
  setTry,
  noTranslate,
  noText,
  lesson,
  showSuccessMessage,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedOptions, setSelectedOptions] = useState<{ text: string; wrong: boolean }[]>([]);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const selectOptions = useMemo<{ text: string; wrong: boolean }[]>(() => {
    const items = [
      ...(optionStory?.sentences.map((s) => ({ text: s.text, wrong: !options?.includes(s.id) })) || []),
      ...alternatives.filter((alt) => alt.text && !alt.media).map((alt) => ({ text: alt.text, wrong: alt.wrong ?? false })),
    ];
    return markers.includes("random") ? shuffle(items) : items;
  }, [alternatives, markers, optionStory?.sentences, options]);

  const onComplete = useCallback(
    (showMessage = true, answer = "", goNextAfterComplete = false) => {
      setStatus(StatusType.Completed);

      if (showMessage && showSuccessMessage) {
        notifyApi.success(successMessage);
      }

      onTaskComplete(answer).then(() => {
        if (goNextAfterComplete) onNext();
      });
    },
    [onTaskComplete, notifyApi, onNext, showSuccessMessage],
  );

  const anySelect = markers.includes("anySelect");

  const onOptionClick = (opt: { text: string; wrong: boolean }) => () => {
    setStatus(StatusType.Empty);

    if (selectedOptions.includes(opt)) {
      setSelectedOptions((prev) => prev.filter((el) => el !== opt));
    } else {
      setSelectedOptions((prev) => [...prev, opt]);
    }
  };

  const onCheck = useCallback(
    (goNextAfterComplete = false) => {
      if (selectedOptions.length === 0) {
        setStatus(StatusType.Error);
        return notifyApi.warning({ message: "Выбери уже что-нибудь!" });
      }

      if (anySelect || selectOptions.every((opt) => (opt.wrong ? !selectedOptions.includes(opt) : selectedOptions.includes(opt)))) {
        onComplete(!anySelect, anySelect ? selectedOptions.map((opt) => opt.text).join("|") : undefined, goNextAfterComplete);
      } else {
        setTry();
        setStatus(StatusType.Error);
        if (selectOptions.some((opt) => opt.wrong && selectedOptions.includes(opt))) {
          notifyApi.error({ message: "Есть лишний ответ!" });
        } else {
          notifyApi.error({ message: "Выбраны не все варианты!" });
        }
      }
    },
    [anySelect, notifyApi, onComplete, selectOptions, selectedOptions, setTry],
  );

  const onHintAnswer = useCallback(() => {
    selectOptions.every((opt) => {
      if (!opt.wrong && !selectedOptions.includes(opt)) {
        setTry(true);
        setSelectedOptions((prev) => [...prev, opt]);
        return false;
      }
      if (opt.wrong && selectedOptions.includes(opt)) {
        setTry(true);

        setSelectedOptions((prev) => prev.filter((el) => el !== opt));
        return false;
      }
      return true;
    });
  }, [selectOptions, selectedOptions, setTry]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        {children && <div className={styles.children}>{children}</div>}

        {(text || translate) && (
          <div className={cx(styles.slate, styles.slate_alignCenter)}>
            <div className={styles.slate_wrapper}>
              {((noText && status === StatusType.Completed) || !noText) && text && (
                <div className={cx(styles.textArea, styles.text)}>{text}</div>
              )}
              {((noTranslate && status === StatusType.Completed) || !noTranslate) && translate && (
                <div className={styles.translate}>{translate}</div>
              )}
            </div>
          </div>
        )}

        <div
          className={cx(multiSelectStyles.multiSelectTask, {
            [multiSelectStyles.multiSelectTask__completed]: status === StatusType.Completed,
          })}
        >
          <Flex gap={10} wrap={"wrap"} justify={"center"}>
            {selectOptions?.map((opt) => (
              <Checkbox
                className={cx(multiSelectStyles.option, {
                  [multiSelectStyles.option__mobile]: isMobile,
                  [multiSelectStyles.option__selected]: selectedOptions.includes(opt),
                  [multiSelectStyles.option__right]:
                    [StatusType.Error, StatusType.Completed].includes(status) && !opt.wrong && selectedOptions.includes(opt),
                  [multiSelectStyles.option__wrong]: status === StatusType.Completed && opt.wrong,
                })}
                key={opt.text}
                onChange={onOptionClick(opt)}
                checked={selectedOptions.includes(opt)}
              >
                <div>{opt.text}</div>
              </Checkbox>
            ))}
          </Flex>
        </div>
        {isMobile && <div className={styles.placeholder} />}
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.MultiSelect}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onCheck={anySelect ? undefined : onCheck}
        onNext={anySelect ? () => onCheck(true) : onNext}
        isCompleted={status === StatusType.Completed || anySelect}
        setDictOpened={setDictOpened}
        onHint={onHintAnswer}
      ></TaskPanel>

      {contextHolder}
    </div>
  );
};

export default MultiSelectTask;
