import { useCallback } from "react";
import { Transforms } from "slate";
import { getCompletedSlate } from "App.helpers";
import { TagType } from "App.types";

const useCompletedSlate = ({ tags, text, editor }: { tags: TagType[]; text: string; editor: any }) => {
  return useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });
    editor.children = [];

    Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [editor, tags, text]);
};

export default useCompletedSlate;
