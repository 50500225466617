import { useEffect, useRef } from "react";

export default function usePrevious(value: any, options?: { keepPrevValue: boolean }) {
  const { keepPrevValue = false } = options || {};
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    if (keepPrevValue && value === undefined) return;
    ref.current = value;
  }, [value, keepPrevValue]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
