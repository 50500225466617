import { useEffect } from "react";
import { delay, findIndex } from "lodash";
import { SentenceType, TranscriptType } from "App.types";

type Props = {
  audio: HTMLAudioElement | undefined;
  sentence: SentenceType;
  setActiveLeaf: Function;
  transcripts?: TranscriptType[];
};

export const useAudioTranscript = ({
  transcripts: jsonTranscript,
  audio,
  sentence: { transcripts: sentenceTranscript },
  setActiveLeaf,
}: Props) => {
  // const { data } = useQuery({
  //   enabled: !transcripts.length && !!text && text.includes(" "),
  //   queryKey: ["transcript", text],
  //   queryFn: () => API.speech.getJson(text, "x-slow"),
  //   staleTime: Infinity,
  //   select: (data) => data.filter((tr) => !SkipTags.includes(tr.text)),
  // });

  const transcripts = jsonTranscript || sentenceTranscript;

  useEffect(() => {
    if (audio)
      audio.ontimeupdate = ({ target: { paused, currentTime } }: any) => {
        const wordIdx = findIndex(transcripts, (el) => el.end > currentTime);
        //const wordIdx = findLastIndex(transcripts, (el) => el.start <= currentTime);

        if (paused) {
          delay(() => setActiveLeaf(null), 600);
        } else {
          setActiveLeaf((prevState: number) => (wordIdx < prevState ? prevState : wordIdx));
        }
      };
  }, [audio, transcripts, setActiveLeaf]);
};
