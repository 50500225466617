import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MediaType, StatusType, TaskType } from "App.types";
import { Radio } from "antd";
import API from "Api";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { STATIC_URL } from "App.constants";
import { isMobile } from "react-device-detect";
import videoStyles from "./VideoSelect.module.scss";
import { shuffle } from "lodash";
import VideoPlayerSingle from "./VideoPlayerSingle";
import TaskPanel from "Components/TaskPanel";

const VideoSelectTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, medias, media, storyId },
  lesson,
  onTaskComplete,
  onNext,
  setDictOpened,
  showGrammar,
  setTry,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [selectedClip, setSelectedClip] = useState<MediaType>();

  const clips = useMemo(() => shuffle(medias), [medias]);

  // initial
  useEffect(() => {
    setStatus(StatusType.Editing);
  }, []);

  const onCheck = useCallback(() => {
    if (selectedClip?.title === text) {
      onTaskComplete();
      setStatus(StatusType.Completed);
    } else {
      setStatus(StatusType.Error);
    }
  }, [selectedClip, text, onTaskComplete]);

  const onHintAnswer = () => {
    API.event.save({ type: "hint", task: TaskType.VideoSelect, lesson: { id: lesson.id }, sentence: { id: sentence.id } });

    setTry(true);
    setSelectedClip(medias.find((m) => m.title === text));
    onTaskComplete();
    setStatus(StatusType.Completed);
  };

  return (
    <div className={styles.sentenceTask}>
      <div>
        {media?.includes(".mp3") && <audio src={`${STATIC_URL}/media/${media}`} controls />}
        <Radio.Group onChange={(e) => setSelectedClip(e.target.value)} disabled={status === StatusType.Completed}>
          <div className={cx(videoStyles.videoSelect, { [videoStyles.videoSelect_mobile]: isMobile })}>
            {clips.map((m) => (
              <Radio value={m} key={m.id}>
                <div
                  className={cx(videoStyles.clip, {
                    [styles.btn_red]: m === selectedClip && status === StatusType.Error,
                    [styles.btn_green]: status === StatusType.Completed && m === selectedClip,
                  })}
                  key={m.id}
                  onClick={() => {
                    if (status !== StatusType.Completed) {
                      setStatus(StatusType.Empty);
                    }
                  }}
                >
                  {/*<ReactPlayer*/}
                  {/*  // onStart={() => setIsPlaying(true)}*/}
                  {/*  playing={m === selectedClip}*/}
                  {/*  playsinline={true}*/}
                  {/*  width={isMobile ? "100%" : ""}*/}
                  {/*  height={"100%"}*/}
                  {/*  url={`${STATIC_URL}/media/${m.url}`}*/}
                  {/*/>*/}
                  <VideoPlayerSingle url={m.url} onFinish={() => {}} isPlaying={m === selectedClip} />
                </div>
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.VideoSelect}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        onCheck={onCheck}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        onHint={onHintAnswer}
      />
    </div>
  );
};

export default VideoSelectTask;
