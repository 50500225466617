import { SentenceType } from "App.types";
import { flatten, last } from "lodash";
import { isMobile } from "react-device-detect";

export const getCompletedPageSlate = (sentences: SentenceType[], source?: string) => {
  const result: any[] = [];
  let newBlock: { children: any[]; type: string } = { children: [], type: "block" };

  sentences.forEach(({ transcripts, tags, text, id, translate, phrases, wrap, grammarLinks }, sentIdx) => {
    const newSentence = {
      id,
      idx: sentIdx,
      phrases,
      tags,
      original: text,
      grammarLinks,
      translate,
      startTime: transcripts[0]?.start,
      endTime: last(transcripts)?.end,
      type: "sentence",
      newLine: !sentIdx || sentences[sentIdx - 1]?.wrap,
      children:
        source !== "Перевод"
          ? flatten(transcripts.map((tr, idx) => [idx && { text: " " }, { ...tr, idx, sentId: id, sentIdx }])).filter((tr) => tr)
          : [{ text: translate, sentId: id }],
    };
    newBlock.children.push(newSentence);

    if (source === "Все вместе") {
      // @ts-ignore
      newSentence.children.push({ text: " " });
      // @ts-ignore
      newSentence.children.push({ text: translate });
    }

    if (wrap) {
      result.push(newBlock);
      newBlock = { children: [], type: "block" };
    }
  });

  if (newBlock.children.length > 0) {
    result.push(newBlock);
  }

  return result.map((block, idx) => ({ ...block, idx }));
};

export const getMaskedSlateForPage = (sentences: SentenceType[], withTranslates = false, withoutLeftMargin = false) => {
  const result: any[] = [];
  let newBlock: { children: any[] } = { children: [] };
  let tabIndex = 0;

  sentences.forEach(({ transcripts, tags, text, id, translate, phrases, wrap, alternatives }, sentIdx) => {
    let index = 0;
    const newSentence = {
      id,
      idx: sentIdx,
      phrases,
      tags,
      translate,
      showTranslate: withTranslates && sentences.some((s) => s.tags.length >= 5) && isMobile,
      startTime: transcripts[0]?.start,
      endTime: last(transcripts)?.end,
      type: "sentence",
      newLine: withoutLeftMargin ? false : !sentIdx || sentences[sentIdx - 1]?.wrap,
      children: !tags.length
        ? [{ text: "" }]
        : flatten(
            tags.map((tag, idx) => [
              idx === 0 || text.includes(`${tags[idx - 1]?.word}${tag.word}`) ? { text: "", idx: index } : { text: " ", idx: index++ },
              {
                idx: index,
                sentIdx,
                phrase: phrases.find((phrase) => phrase.positionsIdx.includes(idx))?.text,
                tabIndex: tag.isMasked ? tabIndex++ : undefined,
                lemma: tag.lemma,
                isMasked: tag.isMasked || false,
                text: tag.isMasked ? "" : tag.word,
                answer: tag.isMasked ? tag.word : undefined,
                alternatives: tag.isMasked ? alternatives.map((alt) => alt.text) : [],
                pos: tag.pos,
                editable: tag.isMasked || false,
              },
            ]),
          )
            .filter((el) => el.text || el.lemma || el.idx === 0)
            .map((el, idx) => ({ ...el, order: idx })),
    };
    if (withTranslates && (!isMobile || sentences.every((s) => s.tags.length < 5))) {
      // @ts-ignore
      newSentence.children.push({ text: " " });
      // @ts-ignore
      newSentence.children.push({ text: translate });
    }
    newBlock.children.push(newSentence);

    if (wrap) {
      result.push(newBlock);
      newBlock = { children: [] };
    }
  });

  if (newBlock.children.length > 0) {
    result.push(newBlock);
  }

  return result;
};
