import useScript from "Components/useScript";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FC, useCallback, useEffect } from "react";
import API from "Api";
import axios, { AxiosInstance } from "axios";
import { API_URL } from "App.constants";
import Cookies from "js-cookie";
import { useQueryClient } from "@tanstack/react-query";

const YandexToken: FC<{ axiosInstance: AxiosInstance; setUser: Function }> = ({ setUser, axiosInstance }) => {
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  useScript(!search ? "https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js" : "", () => {
    if (!search) {
      // @ts-ignore
      window.YaSendSuggestToken(window.location.origin, {
        flag: true,
      });
    }
  });

  const novigate = useNavigate();

  const handleYandexResponse = useCallback(
    (token: string) => {
      axios
        .get(`${API_URL}/users/afterLogin`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: false })
        .then(({ data }) => {
          setUser({ ...data, isEnglish: data.lang === "en" });
          axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;

          Cookies.set("token", token, { expires: 24 * 60 * 60 * 31 });
          Cookies.set("user", JSON.stringify({ ...data, isEnglish: data.lang === "en" }), { expires: 24 * 60 * 60 * 31 });

          queryClient.setDefaultOptions({ queries: { enabled: true } });
          queryClient.resetQueries({ fetchStatus: "idle" }).then();

          novigate(searchParams.get("state") || "/");
        });
    },
    [axiosInstance.defaults.headers, novigate, queryClient, searchParams, setUser],
  );

  useEffect(() => {
    if (search) {
      const code = searchParams.get("code");
      code && API.user.yandexCode(code).then((data: any) => handleYandexResponse(data.access_token));
    }
  }, [handleYandexResponse, search, searchParams]);

  return null;
};

export default YandexToken;
