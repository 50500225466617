import { Button, Drawer, Form, Input, notification, Select } from "antd";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import API from "Api";
import React, { FC, useEffect, useState } from "react";
import { CommentOutlined } from "@ant-design/icons";

const ReportFeedback: FC<{
  task: string | undefined;
  storyId: number | string | undefined;
  lessonId: string | number;
  sentId?: number | string;
}> = ({ lessonId, storyId, sentId, task }) => {
  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });
  const [showDrawer, setShowDrawer] = useState(false);

  const [form] = Form.useForm<{ message: string; type: string }>();

  const type = Form.useWatch("type", form);

  const { data: reports = [] } = useQuery({
    enabled: showDrawer && !!type,
    queryKey: ["reports", storyId, sentId, type, task, lessonId],
    queryFn: () => {
      // @ts-ignore
      return API.feedback.getReports({ storyId, sentenceId: sentId, type, task, lessonId });
    },
    staleTime: Infinity,
  });

  const client = useQueryClient();

  const onFinish = (values: { type: string; message: string }) => {
    return API.feedback
      .save({
        id: reports[0]?.id,
        type: values.type,
        question: values.message,
        // @ts-ignore
        sentenceId: sentId,
        // @ts-ignore
        storyId,
        task,
        // @ts-ignore
        lessonId,
        meta: `UA: ${window.navigator.userAgent}`,
      })
      .then(() => {
        setShowDrawer(false);
        form.resetFields();
        notifyApi.success({ message: "Сообщение отправлено!" });
        client.invalidateQueries({ queryKey: ["reports", storyId, sentId, type, task] });
      });
  };

  useEffect(() => {
    if (reports.length > 0 && !form.getFieldValue("message")) {
      form.setFieldsValue({ message: reports[0].question });
    }
  }, [form, reports]);

  return (
    <>
      <Drawer
        styles={{ body: { padding: 15 }, header: { display: "none" } }}
        onClose={() => setShowDrawer(false)}
        height={240}
        placement={"bottom"}
        open={showDrawer}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item rules={[{ required: true }]} name={"type"} style={{ margin: "10px 0" }}>
            <Select
              placeholder={"Выберите тип"}
              options={[
                { value: "mistake", label: "Ошибка в задании" },
                { value: "userAnswer", label: "Не принимает правильный ответ" },
                { value: "other", label: "Прочее" },
              ]}
            />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name={"message"} style={{ margin: "10px 0" }}>
            <Input.TextArea rows={3} placeholder={"Опишите проблему"} />
          </Form.Item>
          <Button htmlType={"submit"} type={"primary"} size={"middle"}>
            отправить
          </Button>
        </Form>
      </Drawer>
      <Button icon={<CommentOutlined />} onClick={() => setShowDrawer(true)} />
      {contextHolder}
    </>
  );
};

export default ReportFeedback;
