import React, { FC, PropsWithChildren, useState } from "react";
import cx from "classnames";
import styles from "./VideoPlayer.module.scss";
import { MediaType } from "App.types";
import VideoPlayerItem from "./VideoPlayerItem";

type Props = {
  medias: Partial<MediaType>[];
  onFinish?: () => any;
  autoPlay?: boolean;
  showCaptions: boolean;
  largeCaptionSize?: boolean;
  className?: string;
  withFullscreen?: boolean;
};

const VideoPlayer: FC<Props & PropsWithChildren> = ({
  withFullscreen,
  className = "",
  largeCaptionSize,
  showCaptions,
  onFinish,
  medias,
  autoPlay = false,
}) => {
  const [activeIdx, setActiveIdx] = useState<number>(0);
  const [isPlaying, setPlaying] = useState(autoPlay);
  const [finished, setFinished] = useState(false);

  return (
    <div
      className={cx(styles.videoPlayer, className, { [styles.largeCaptions]: largeCaptionSize })}
      onClick={() => {
        if (finished) {
          setActiveIdx(0);
          setFinished(false);
        }
        setPlaying((prev) => !prev);
      }}
    >
      {medias.map((clip, idx) => (
        <VideoPlayerItem
          key={clip.id}
          withBackground={medias.length > 1}
          withFullscreen={withFullscreen}
          isPlaying={isPlaying}
          clip={clip}
          text={showCaptions ? clip.title : ""}
          isActive={activeIdx === idx}
          onFinish={() => {
            setActiveIdx((prev = 0) => {
              if (prev === medias.length - 1) {
                setPlaying(false);
                onFinish?.();
                setFinished(true);
                return prev;
              }

              return prev + 1;
            });
          }}
        />
      ))}
    </div>
  );
};

export default VideoPlayer;
