import { AlternativeType, LessonType, SentenceType, StatusType } from "App.types";
import React, { FC, useEffect, useMemo, useState } from "react";
import { getCompletedSlate, textIncludesAnswer } from "App.helpers";
import { SkipTags } from "App.constants";
import StoryDialogSentence from "Components/StoryDialogSentence";
import RecordButton from "Components/RecordButton";
import { Button, Flex, Tooltip } from "antd";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import { last } from "lodash";
import keyboardSvg from "Assets/keyboard.svg";
import DialogTaskWriting from "./DialogTaskWriting";
import styles from "./DialogTaskOptions.module.scss";

const DialogTaskTranslate: FC<{
  onClickRecording: () => any;
  audioStatus: StatusType;
  sentence: SentenceType;
  onSuccess: Function;
  storyId?: number;
  textFromSpeech: string;
  isSpeaking: boolean;
  audio: HTMLAudioElement;
  lesson: LessonType;
}> = ({
  textFromSpeech,
  onClickRecording,
  sentence: { tags, alternatives, text, isLeft },
  sentence,
  onSuccess,
  storyId,
  audioStatus,
  isSpeaking,
  audio,
  lesson,
}) => {
  const [taskStatus, setTaskStatus] = useState<StatusType>(StatusType.Empty);
  const [answer, setAnswer] = useState<Partial<AlternativeType>>();
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);
  const [isWriting, setIsWriting] = useState(false);

  // const answerAudio = useMemo(() => new Audio(getSentenceAudioUrl(id, text, voice || VOICES[10].value)), [id, text, voice]);

  const completedSlate = useMemo(() => getCompletedSlate(tags || [], text), [text, tags]);
  const filteredRightTags = useMemo(() => completedSlate.filter((el) => el.word && !SkipTags.includes(el.word)), [completedSlate]);

  useSpeechCheck({ status: taskStatus, sentence, textFromSpeech, setCompletedTagIdx, completedTagIdx, filteredRightTags });

  useEffect(() => {
    if (taskStatus !== StatusType.Completed && completedTagIdx >= last<any>(filteredRightTags)?.idx) {
      setTaskStatus(StatusType.Completed);
      onSuccess(sentence.id);
    }
  }, [completedTagIdx, filteredRightTags, onSuccess, sentence.id, taskStatus]);

  useEffect(() => {
    if (!textFromSpeech) return;

    if (taskStatus !== StatusType.Completed) {
      if (textIncludesAnswer(textFromSpeech, text)) {
        setTaskStatus(StatusType.Completed);
        return onSuccess();
      }

      const alt = alternatives.find((alt) => textIncludesAnswer(textFromSpeech, alt.text));
      if (alt) {
        setAnswer(alt);
        setTaskStatus(StatusType.Completed);
        return onSuccess();
      }
    }
  }, [textFromSpeech, text, alternatives, taskStatus, onSuccess]);

  return (
    <div className={styles.dialogTaskOptions}>
      <Flex gap={5} align={"center"} style={{ flexDirection: isLeft ? "row-reverse" : undefined }}>
        {taskStatus !== StatusType.Completed && !isWriting && (
          <RecordButton
            onClick={() => {
              setIsWriting(false);
              setCompletedTagIdx(-1);
              onClickRecording();
            }}
            isRecording={audioStatus === StatusType.IsRecording}
            loading={audioStatus === StatusType.Loading}
            isSpeaking={isSpeaking}
          />
        )}
        {taskStatus !== StatusType.Completed && !isWriting && (
          <Tooltip title={"Буду печатать"}>
            <Button
              size={"middle"}
              onClick={() => {
                if (audioStatus === StatusType.IsRecording) onClickRecording();
                setIsWriting((prev) => !prev);
              }}
              type={"text"}
              icon={<img alt={"keyboard"} style={{ height: 36, opacity: isWriting ? 1 : 0.5 }} src={keyboardSvg} />}
            />
          </Tooltip>
        )}
        {isWriting ? (
          <DialogTaskWriting sentence={sentence} lesson={lesson} onTaskComplete={onSuccess} />
        ) : (
          <StoryDialogSentence
            completedTagIdx={completedTagIdx}
            storyId={storyId}
            audio={audio}
            tags={answer?.tags}
            sentence={sentence}
            active={taskStatus !== StatusType.Completed}
            showTranslate={taskStatus !== StatusType.Completed}
            hideTooltip={taskStatus !== StatusType.Completed}
          />
        )}
      </Flex>
    </div>
  );
};

export default DialogTaskTranslate;
