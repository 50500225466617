import { useSlate, useSlateSelection } from "slate-react";
import { forwardRef, useEffect } from "react";
import { scroller } from "react-scroll";
import usePrevious from "Hooks/usePrevious";
import { Editor } from "slate";

const PageMaskedParent = forwardRef(({ setActiveSent, children, ...rest }: any, ref) => {
  const selection = useSlateSelection();
  const editor = useSlate();

  const prevPath = usePrevious(selection?.focus.path, { keepPrevValue: true });

  useEffect(() => {
    if (selection?.focus && prevPath !== selection.focus.path) {
      const [node] = Editor.node(editor, { path: selection.focus.path, offset: 0 });
      setActiveSent?.(node?.sentIdx);
      scroller.scrollTo(`${node?.sentIdx}`, { offset: -200, smooth: true, duration: 1000 });
    }
  }, [selection, setActiveSent, prevPath, editor]);

  return (
    <div {...rest} ref={ref}>
      {children}
    </div>
  );
});

export default PageMaskedParent;
