import { Avatar, Button, Drawer, Form, Input, List, Space, Typography } from "antd";
import React, { FC, useContext } from "react";
import API from "Api";
import { SentenceType } from "App.types";
import { useQuery } from "@tanstack/react-query";
import styles from "./SentenceComments.module.scss";
import { UserContext } from "App";
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

const SentenceComments: FC<{ sentence?: SentenceType; toggle: Function; isOpen: boolean }> = ({ isOpen, sentence, toggle }) => {
  const { data, refetch } = useQuery({
    queryFn: () => API.comment.getList({ id: sentence?.id }),
    enabled: !!sentence?.id,
    queryKey: ["comments", sentence?.id],
    staleTime: Infinity,
  });

  const [form] = Form.useForm();
  const user = useContext(UserContext);

  const sendComment = ({ text }: any) => {
    sentence &&
      API.comment.post({ sentence: { id: sentence.id }, text }).then(() => {
        form.resetFields();
        refetch();
      });
  };

  return (
    <Drawer
      title={
        <div>
          <div>{sentence?.text}</div>
          <div>{sentence?.translate}</div>
        </div>
      }
      placement={"right"}
      open={isOpen}
      onClose={() => toggle(false)}
      className={styles.comments}
    >
      <List
        size={"small"}
        locale={{ emptyText: " " }}
        rowKey={"id"}
        dataSource={data}
        renderItem={(item) => (
          <List.Item className={styles.comment}>
            <List.Item.Meta
              avatar={<Avatar src={item.user.picture} />}
              title={
                <Space size={"small"}>
                  <span className={styles.comments__user}>{item.user.name}</span>
                  <Typography.Text className={styles.comments__date} type={"secondary"} italic>
                    {
                      // @ts-ignore
                      dayjs(item.timestamp).fromNow()
                    }
                  </Typography.Text>
                </Space>
              }
              description={<span className={styles.comments__text}>{item.text}</span>}
            />
          </List.Item>
        )}
      />
      <div className={styles.newComment}>
        <Avatar src={user?.picture} />
        <Form form={form} onFinish={sendComment}>
          <Form.Item name={"text"} rules={[{ required: true }]}>
            <Input.TextArea name={"comment"} placeholder={"напишите свой комментарий к заданию"} />
          </Form.Item>
          <Form.Item>
            <Button htmlType={"submit"} type={"primary"}>
              добавить
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};

export default SentenceComments;
