import { Button, Flex, Progress, Segmented, Skeleton } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import API from "Api";
import { STATIC_URL } from "App.constants";
import { groupBy, last } from "lodash";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { SentenceType, StatusType } from "App.types";
import ButtonClose from "Components/ButtonClose";
import styles from "./Book.module.scss";
import { PauseOutlined, SoundFilled } from "@ant-design/icons";
import PageMobile from "./Page/PageMobile";
import { usePlaySentence } from "Pages/Books/Page/usePlaySentence";

const Book: FC = () => {
  const [status, setStatus] = useState<StatusType>(StatusType.Completed);
  const [source, setSource] = useState<string>("Оригинал");
  const [activeSent, setActiveSent] = useState<number>();
  const [activeWordIdx, setActiveWordIdx] = useState<number | undefined>(undefined);

  const navigate = useNavigate();
  const { title = "", page = "1" } = useParams();
  const { data: story } = useQuery({
    queryFn: () => API.story.getByTitle(title),
    enabled: !!title,
    queryKey: ["story", title],
    staleTime: Infinity,
  });

  const { data: dataLeft = [], isLoading } = useQuery({
    queryFn: () => story && API.sentence.getListByStoryId(story?.id, page),
    enabled: !!story,
    queryKey: ["sentences", story?.id, +page],
    staleTime: Infinity,
    placeholderData: keepPreviousData, // for smooth progress increasing
  });

  const { data: dataRight = [] } = useQuery({
    queryFn: () => story && API.sentence.getListByStoryId(story?.id, +page + 1),
    enabled: !!story,
    queryKey: ["sentences", story?.id, +page + 1],
    staleTime: Infinity,
    placeholderData: keepPreviousData, // for smooth progress increasing
  });

  const { data: transcripts } = useQuery({
    staleTime: Infinity,
    queryKey: ["transcripts", story?.id],
    enabled: !!story?.id,
    queryFn: () => story && API.transcriptions.getByStoryId(story.id),
    select: (data) => groupBy(data, "sent"),
  });

  const sentencesLeft = useMemo<SentenceType[]>(
    () => dataLeft.map((sent) => ({ ...sent, transcripts: transcripts?.[sent.id] || [] })),
    [dataLeft, transcripts],
  );

  const sentencesRight = useMemo<SentenceType[]>(
    () => dataRight.map((sent) => ({ ...sent, transcripts: transcripts?.[sent.id] || [] })),
    [dataRight, transcripts],
  );

  const audio = useMemo(() => new Audio(`${STATIC_URL}/stories/${encodeURI(title)}/audio.m4a`), [title]);

  useEffect(() => {
    audio.addEventListener("error", console.error);
    audio?.addEventListener("play", () => setStatus(StatusType.isPlaying));
    audio?.addEventListener("pause", () => setStatus(StatusType.Completed));
    audio?.addEventListener("ended", () => setStatus(StatusType.Completed));
    // audio?.addEventListener("canplay", () => setStatus(StatusType.Completed));

    return () => audio?.pause();
  }, [audio]);

  const onNext = () => {
    audio.pause();
    audio.currentTime = 0;
    navigate(`/books/${title}/${+page + 2}`);
  };

  const sentences = useMemo(() => [...sentencesLeft, ...sentencesRight], [sentencesLeft, sentencesRight]);

  const progress = useMemo(() => (((last(sentences)?.order || 0) + 1) / (story?.sentCount || 0)) * 100, [sentences, story]);

  const isLastPage = useMemo(() => last(sentences)?.order === (story?.sentCount || 0) - 1, [sentences, story]);

  const play = (from: number = sentences?.[0].transcripts[0].start || 0, to: number = last(last(sentences)?.transcripts)?.end || 0) => {
    audio.currentTime = !audio.currentTime || audio.currentTime >= to ? from : audio.currentTime;

    audio.ontimeupdate = ({ target: { currentTime } }: any) => {
      if (currentTime >= to) {
        audio.pause();
      }
    };

    audio.play();
  };

  const playSentence = usePlaySentence({ audio, sentences, setActiveWordIdx, setActiveSent });

  return (
    <div className={styles.book}>
      <Flex className={styles.header}>
        <Progress showInfo={false} percent={progress} strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }} />
        <ButtonClose path={"/"} />
      </Flex>

      <div className={styles.source}>
        <Segmented onChange={(v) => setSource(v as string)} options={["Оригинал", "Перевод", "Все вместе"]} />
      </div>

      <SwitchTransition>
        <CSSTransition key={`${page}${isLoading}${source}`} timeout={500} classNames={"fade"}>
          {isLoading ? (
            <Skeleton loading />
          ) : (
            <div className={styles.doublePage}>
              <PageMobile
                story={story}
                activeSent={activeSent}
                activeWordIdx={activeWordIdx}
                playSentence={playSentence}
                source={source}
                sentences={sentencesLeft}
                title={title}
                page={page}
              />
              <PageMobile
                story={story}
                activeSent={activeSent}
                activeWordIdx={activeWordIdx}
                playSentence={playSentence}
                source={source}
                sentences={sentencesRight}
                title={title}
                page={`${+page + 1}`}
              />
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>

      <div className={styles.panel}>
        <div className={styles.panel__content}>
          <Button
            type={"link"}
            style={{ visibility: +page > 1 ? undefined : "hidden" }}
            onClick={() => navigate(`/books/${title}/${+page - 2}`)}
          >
            Назад
          </Button>

          {status === StatusType.isPlaying ? (
            <Button icon={<PauseOutlined />} onClick={() => audio?.pause()} />
          ) : (
            <Button disabled={status === StatusType.Error} icon={<SoundFilled />} onClick={() => play()} />
          )}

          {isLastPage ? (
            <Button className={styles.btn_finish} type={"primary"} shape={"round"} onClick={() => navigate("/")}>
              Завершить
            </Button>
          ) : (
            <Button className={styles.btn_next} type={"primary"} shape={"round"} onClick={onNext}>
              Далее
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Book;
