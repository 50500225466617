import { TaskType } from "App.types";
import SentenceTask from "./SentenceTask";
import CollectTask from "./CollectTaskDnd";
import MaskedTask from "./MaskedTask";
import SelectTask from "./SelectTask";
import ListenTask from "./ListenTask";
import ReadTask from "./ReadTask";
import AudioSelectTask from "./AudioSelectTask";
import SpellerTask from "./SpellerTask";
import ConstructorTask from "./ConstructorTask";
import ReverseTranslateTask from "./ReverseTranslateTask";
import ReverseSelectTask from "./ReverseSelectTask";
import ReadByWordTask from "./ReadByWordTask";
import SpellerSelectTask from "./SpellerSelectTask";
import PictureSelectTask from "./PictureSelectTask";
import SpellerCollect from "./SpellerCollect";
import MaskedDragDrop from "./MaskedDragDrop";
import WelcomeTask from "./WelcomeTask";
import MultiSelectTask from "./MultiSelectTask";
import VideoTask from "./VideoTask/VideoTask";
import AudioCollectTaskDnd from "./AudioCollectTaskDnd";
import MultiSelectReverseTask from "./MultiSelectReverseTask";
import RepeatTask from "./RepeatTask";
import VoiceTranslateTask from "./VoiceTranslateTask";
import DialogTask from "./DialogTask";
import ScrollerTask from "./ScrollerTask";
import StorySelectTask from "./StorySelectTask";
import IntroTask from "./IntroTask";
import FeedbackSelectTask from "./FeedbackTasks/FeedbackSelectTask";
import FeedbackMultiTask from "./FeedbackTasks/FeedbackMultiTask";
import FeedbackTextTask from "./FeedbackTasks/FeedbackTextTask";
import FeedbackCalendarTask from "./FeedbackTasks/FeedbackCalendarTask";
import OneButtonTask from "./OneButtonTask";
import VideoSelectTask from "./VideoSelectTask";
import VideoDragDrop from "./VideoDragDrop/VideoDragDrop";
import ConversationTask from "./ConversationTask";

export const TaskComponents: any = {
  [TaskType.Masked]: MaskedTask,
  [TaskType.Collect]: CollectTask,
  [TaskType.AudioCollect]: AudioCollectTaskDnd,
  [TaskType.SpellerCollect]: SpellerCollect,
  [TaskType.Select]: SelectTask,
  [TaskType.MultiSelect]: MultiSelectTask,
  [TaskType.MultiSelectReverse]: MultiSelectReverseTask,
  [TaskType.ReverseSelect]: ReverseSelectTask,
  [TaskType.AudioSelect]: AudioSelectTask,
  [TaskType.Listen]: ListenTask,
  [TaskType.Reverse]: ReverseTranslateTask,
  [TaskType.Read]: ReadTask,
  [TaskType.ReadByWord]: ReadByWordTask,
  [TaskType.Repeat]: RepeatTask,
  [TaskType.VoiceTranslate]: VoiceTranslateTask,
  [TaskType.Convert]: SentenceTask,
  [TaskType.Dictate]: SentenceTask,
  [TaskType.Translate]: SentenceTask,
  [TaskType.Speller]: SpellerTask,
  [TaskType.SpellerListen]: SpellerTask,
  [TaskType.SpellerSelect]: SpellerSelectTask,
  [TaskType.Constructor]: ConstructorTask,
  [TaskType.PictureSelect]: PictureSelectTask,
  [TaskType.MaskedDragDrop]: MaskedDragDrop,
  [TaskType.Grammar]: WelcomeTask,
  [TaskType.Video]: VideoTask,
  [TaskType.ActiveDialog]: DialogTask,
  [TaskType.Scroller]: ScrollerTask,
  [TaskType.StorySelect]: StorySelectTask,
  [TaskType.Intro]: IntroTask,
  [TaskType.FeedbackSelect]: FeedbackSelectTask,
  [TaskType.FeedbackMultiple]: FeedbackMultiTask,
  [TaskType.FeedbackText]: FeedbackTextTask,
  [TaskType.FeedbackCalendar]: FeedbackCalendarTask,
  [TaskType.OneButton]: OneButtonTask,
  [TaskType.VideoSelect]: VideoSelectTask,
  [TaskType.VideoDragDrop]: VideoDragDrop,
  [TaskType.Conversation]: ConversationTask,
};
