import { RealtimeClient } from "@openai/realtime-api-beta";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Input } from "antd";
import styles from "./ConversationTask.module.scss";
import { ItemType } from "@openai/realtime-api-beta/dist/lib/client";
import { SendOutlined } from "@ant-design/icons";
import { WavRecorder, WavStreamPlayer } from "./wavtools";
import { instructions } from "./ConversationTask.consts";
import RecordButton from "Components/RecordButton";

// interface RealtimeEvent {
//   time: string;
//   source: "client" | "server";
//   count?: number;
//   event: { [key: string]: any };
// }

const ConversationTask = () => {
  const [items, setItems] = useState<ItemType[]>([]);
  const [isRecording, setIsRecording] = useState(false);

  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef<RealtimeClient>(new RealtimeClient({ url: "" }));

  const onStart = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();

    // client.updateSession({ voice: "alloy" });
    client.updateSession({
      // @ts-ignore
      // turn_detection: { type: "none" }, // or 'server_vad'
      input_audio_transcription: { model: "whisper-1" },
    });

    // Set instructions
    client.updateSession({ instructions: instructions });

    client.sendUserMessageContent([{ type: "input_text", text: "Hi!" }]);

    // Set up event handling
    //   client.on("conversation.updated", (event: any) => {
    //     const { item, delta } = event;
    //     console.log(delta?.audio?.length);
    //
    //     const isNew = !messages.some((m) => m.id === item.id);
    //     if (isNew) {
    //       messages.push(item);
    //     } else {
    //       setMessages((prev) => {
    //         prev.pop();
    //         return [...prev, item];
    //       });
    //     }
    //
    //     const items = client.conversation.getItems();
    //
    //     setMessages(items);
    //
    //     if (delta?.audio) {
    //       const blob = new Blob([delta.audio], { type: "audio/wav" });
    //       //const audio = new Audio(URL.createObjectURL(blob));
    //       // audio.play();
    //     }
    //   });
  }, []);

  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // handle realtime events from client + server for event logging
    // client.on("realtime.event", (realtimeEvent: RealtimeEvent) => {
    //   setRealtimeEvents((realtimeEvents) => {
    //     const lastEvent = realtimeEvents[realtimeEvents.length - 1];
    //     if (lastEvent?.event.type === realtimeEvent.event.type) {
    //       // if we receive multiple events in a row, aggregate them for display purposes
    //       lastEvent.count = (lastEvent.count || 0) + 1;
    //       return realtimeEvents.slice(0, -1).concat(lastEvent);
    //     } else {
    //       return realtimeEvents.concat(realtimeEvent);
    //     }
    //   });
    // });

    client.on("error", (event: any) => console.error(event));

    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    client.on("conversation.updated", async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000);
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  const onStop = useCallback(async () => {
    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const [form] = Form.useForm<{ userText: string }>();

  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    setIsRecording(false);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  console.log(items);

  return (
    <div className={styles.conversationTask}>
      <Button onClick={onStart}>connect</Button>
      <Button onClick={onStop}>disconnect</Button>

      <RecordButton isRecording={isRecording} onMouseDown={startRecording} onMouseUp={stopRecording} />

      <div className={styles.messages}>
        {items.map((m) => (
          <div key={m.id} className={styles.message}>
            <div className={styles.role}>{m.role}</div>
            <div>{m.formatted.text || m.formatted.transcript}</div>
          </div>
        ))}
      </div>

      <Form form={form}>
        <Form.Item name={"userText"}>
          <Input.Search
            enterButton={<SendOutlined />}
            onSearch={(v, event) => {
              clientRef.current.sendUserMessageContent([{ type: "input_text", text: v }]);
              form.resetFields();
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConversationTask;
