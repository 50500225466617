import cx from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./PageMaskedLeaf.module.scss";
import { Tooltip } from "antd";
import { useSelected, useSlateSelection, useSlateStatic } from "slate-react";
import { delay, isEqual } from "lodash";
import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { POS_TAGS, SkipTags } from "App.constants";
import { Editor } from "slate";
import usePrevious from "Hooks/usePrevious";

const tooltip = {
  order: "неверный порядок слов",
  typo: "написано с ошибками",
  wrong: "неверное слово",
  lemma: "неверная форма слова",
  missed: "пропущено слово",
};

const PageMaskedLeaf = ({ showTooltip = true, completed, attributes, children, leaf, showErrors, active, underlined }: any) => {
  const [hint, setHint] = useState<string>();

  const { pos = "", lemma, idx, phrase = "", tabIndex, editable, status, answer, text = "", isMasked = false } = leaf;

  const selected = useSelected();
  const editor = useSlateStatic();
  const selection = useSlateSelection();
  const prevSelectionPath = usePrevious(selection?.anchor.path, { keepPrevValue: true });

  const word = phrase || (["was", "were", "is", "are", "am"].includes(text) ? text : lemma);

  const { data: dict } = useQuery({
    queryKey: ["word", word, phrase ? undefined : POS_TAGS[pos] || ""],
    queryFn: () => API.dictionary.findOne(word, phrase ? undefined : POS_TAGS[pos] || ""),
    staleTime: Infinity,
    enabled: !SkipTags.includes(word) && !!word,
    select: (data) => data?.translates?.join(", ") || "",
  });

  // dictionary hint
  useEffect(() => {
    if (dict && selected && selection && !isEqual(selection.focus.path, prevSelectionPath)) {
      const [node] = Editor.node(editor, selection.focus.path);

      if (node.idx === idx) {
        setHint(dict);
      } else {
        setHint(undefined);
      }
    }
  }, [editor, dict, selected, selection, text, idx, prevSelectionPath]);

  useEffect(() => {
    if (hint) {
      delay(() => setHint(""), 2000);
    }
  }, [hint]);

  return (
    // @ts-ignore
    <Tooltip open={!isMasked && !!hint && showTooltip} title={hint || (showErrors && tooltip[status])}>
      <span
        {...attributes}
        spellCheck="false"
        autoCorrect="off"
        tabIndex={tabIndex}
        onClick={() => setHint(phrase ? `${word} - ${dict}` : dict)}
        contentEditable={editable === false ? false : undefined}
        //style={editable === false ? { userSelect: "none" } : {}}
        className={cx(styles.maskedLeaf, {
          [styles.maskedLeaf_masked]: isMasked && !completed,
          [styles.maskedLeaf_translate]: !lemma,
          [styles.maskedLeaf_wrong]: showErrors && status === "wrong",
          [styles.maskedLeaf_typo]: showErrors && status === "typo",
          [styles.maskedLeaf_form]: showErrors && ["lemma", "article"].includes(status),
          [styles.maskedLeaf_empty]: showErrors && ["empty", "wrong"].includes(status),
          [styles.maskedLeaf_order]: showErrors && status === "order",
          [styles.maskedLeaf_completed]: status === "completed" || underlined,
          [styles.maskedLeaf_active]: active || (!!hint && !isMasked),
          [styles.maskedLeaf_hint]: status === "hint",
        })}
        style={isMasked ? { minWidth: answer.length * 10 } : { userSelect: "none" }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default PageMaskedLeaf;
