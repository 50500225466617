import { useContext, useEffect, useState } from "react";
import { SentenceType, StatusType } from "App.types";
import { isEqualEnglishWords } from "App.helpers";
import { flatten, last } from "lodash";
import { NotifyContext } from "App";
import { ResultType } from "Hooks/useDeepgramAlt";

type Props = {
  status?: StatusType;
  speechResults: ResultType[];
  completedTagIdx: number;
  setCompletedTagIdx: Function;
  sentence?: SentenceType;
  filteredRightTags: any[];
};

const WRONG_TRIES = 5;

export const useSpeechCheckAlt = ({
  status,
  speechResults = [],
  setCompletedTagIdx,
  completedTagIdx,
  sentence,
  filteredRightTags: tags,
}: Props) => {
  // const [completedSpeech, setCompletedSpeech] = useState("");
  const [wrongCount, setWrongCount] = useState(0);

  useEffect(() => {
    // setCompletedSpeech("");
    setWrongCount(0);
  }, [sentence, status]);

  const notifyApi = useContext(NotifyContext);

  useEffect(() => {
    if (wrongCount > WRONG_TRIES) {
      const activeTag = tags[tags.findIndex((t) => t.idx > completedTagIdx)];
      if (activeTag) {
        setCompletedTagIdx(activeTag.idx);
        notifyApi?.warning({
          message: (
            <div style={{ fontSize: 18 }}>
              подсказка: <b>{activeTag.word}</b>
            </div>
          ),
        });
      }
      setWrongCount(0);
    }
  }, [completedTagIdx, notifyApi, setCompletedTagIdx, tags, wrongCount]);

  useEffect(() => {
    //if (speechResults) setWrongCount((prev) => prev + 1);
  }, [speechResults]);

  useEffect(() => {
    if (!speechResults.length || completedTagIdx >= last<any>(tags)?.idx || status === StatusType.Completed) return;

    if (
      speechResults.some(
        (r) =>
          isEqualEnglishWords(r.transcript, sentence?.text) ||
          sentence?.alternatives.some((alt) => !alt.wrong && isEqualEnglishWords(alt.text, r.transcript)),
      )
    ) {
      setCompletedTagIdx(Infinity);
    } else {
      // const newText = `${textFromSpeech} `.replace(completedSpeech, "").trim();
      // if (!newText) return;

      speechResults.map((result) => {
        const speechWords = flatten(
          result.words.map((el) => el.punctuated_word.replace("n't", " n't").replace("'s", " 's").replace("'m", " 'm").split(" ")),
        );

        const isCompleted =
          speechWords.length === tags.length && speechWords.every((word, idx: number) => isEqualEnglishWords(tags[idx].word, word));

        if (isCompleted) {
          return Infinity;
        } else {
          speechWords.some((_, idx) => {
            return speechWords.slice(idx, speechWords.length).every((word, wordIdx) => {
              const activeTag = tags[tags.findIndex((t) => t.idx > completedTagIdx) + wordIdx];

              if (word && activeTag && (isEqualEnglishWords(activeTag?.word, word) || isEqualEnglishWords(word, activeTag?.word))) {
                setCompletedTagIdx(activeTag.idx);
                setWrongCount(0);
                return true;
              }
              return false;
            });
          });

          return -1;
          // setCompletedSpeech(`${textFromSpeech} `);
        }

        // const nextIdx = Math.max(...newCompletedIdxs);
      });
    }
  }, [sentence, tags, speechResults, status, completedTagIdx, setCompletedTagIdx]);
};
