import { SentenceType, TagType } from "App.types";
import React, { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { keys, shuffle } from "lodash";
import { Flex, notification, Space } from "antd";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import cx from "classnames";
import { arrayMove } from "../SentenceTask.helpers";
import styles from "./StoryMaskedDragDrop.module.scss";
import { successMessage } from "App.constants";

const StorySentenceDragDrop: FC<{
  ref: any;
  onTaskComplete: () => void;
  sentences: SentenceType[];
  isCompleted: boolean;
  showTranslate: boolean;
}> = forwardRef(({ showTranslate, sentences, onTaskComplete, isCompleted }, ref) => {
  const [dropTags, setDropTags] = useState<{ [key: string]: { text: string; draggableId: string } | undefined }>({});
  const [tagsToSelect, setTagsToSelect] = useState<SentenceType[]>(shuffle(sentences.filter((s) => s.text)));
  const [wrongTags, setWrongTags] = useState<SentenceType[]>([]);
  const [selectedTag, setSelectedTag] = useState<SentenceType>();

  const tagsAsObjects: { [key: string]: TagType } = useMemo(
    () => sentences.reduce((acc, t) => ({ ...acc, [`${t.id}`]: t }), {}),
    [sentences],
  );

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const onCheck = useCallback(() => {
    let hasErrors = false;
    sentences.forEach((s) => {
      if (s.text && dropTags[`${s.id}`]?.draggableId !== `${s.id}`) {
        hasErrors = true;
        setWrongTags((prev) => [...prev, s]);
      }
    });

    notifyApi.destroy();

    if (!hasErrors) {
      onTaskComplete();
      notifyApi.success(successMessage);
    } else {
      if (tagsToSelect.length) {
        notifyApi.error({ message: "Заполни все пропуски!" });
      } else {
        notifyApi.error({ message: "Есть ошибки!" });
      }
    }
  }, [dropTags, notifyApi, onTaskComplete, sentences, tagsToSelect.length]);

  useImperativeHandle(
    ref,
    () => {
      return { onCheck };
    },
    [onCheck],
  );

  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    const tag = sentences.find((t) => `${t.id}` === draggableId);

    if (source.droppableId === destination?.droppableId && source.index === destination.index) return;

    if (destination?.droppableId === "answers" && source.droppableId === "answers") {
      setTagsToSelect((prev) => arrayMove(prev, source.index, destination.index));

      return;
    }

    if (destination?.droppableId && tag) {
      setWrongTags((prev) => prev.filter((s) => `${s.id}` !== destination?.droppableId));

      if (source.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev.splice(source.index, 1);
          return prev;
        });

        setDropTags((prev) => ({
          ...prev,
          [destination.droppableId]: { text: tag.text, draggableId },
        }));

        if (dropTags[destination.droppableId]) {
          const sent = sentences.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
          sent && setTagsToSelect((prev) => [...prev, { ...sent }]);
        }

        return;
      }

      if (destination.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev.splice(destination.index, 0, tag);
          return prev;
        });

        if (source.droppableId !== "answers") {
          setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined }));
        }

        return;
      }

      setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined, [destination.droppableId]: { text: tag.text, draggableId } }));

      if (dropTags[destination.droppableId]) {
        const sent = sentences.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
        sent && setTagsToSelect((prev) => [...prev, { ...sent }]);
      }
    }
  };

  useEffect(() => {
    const hasWrong = keys(dropTags).some((key) => tagsAsObjects[key]?.word !== dropTags[key]?.text);

    if (!hasWrong && tagsToSelect.length === 0) {
      onTaskComplete();
    }
  }, [dropTags, tagsAsObjects, sentences.length, onTaskComplete, tagsToSelect.length]);

  const onDropClick = useCallback(
    (targetId: string) => {
      if (!selectedTag) return;

      setWrongTags((prev) => prev.filter((s) => `${s.id}` !== targetId));

      setTagsToSelect((prev) => {
        const filtered = prev.filter((t) => t.id !== selectedTag.id);

        if (dropTags[targetId]) {
          const sent = sentences.find((s) => `${s.id}` === dropTags[targetId]?.draggableId);
          if (sent) return [...filtered, sent];
        }
        return filtered;
      });

      const prevDropZone = keys(dropTags).find((k) => dropTags[k]?.draggableId === `${selectedTag.id}`);

      setDropTags((prev) => ({
        ...prev,
        [prevDropZone || ""]: undefined,
        [targetId]: { text: selectedTag.text, draggableId: `${selectedTag.id}` },
      }));

      setSelectedTag(undefined);
    },

    [selectedTag, dropTags, sentences],
  );

  return (
    <div className={styles.MaskedDragDrop}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"answers"} direction={"horizontal"}>
          {(provided, snapshot) => (
            <Flex
              wrap={"wrap"}
              gap={10}
              className={styles.tags}
              {...provided.droppableProps}
              ref={provided.innerRef}
              // isDraggingOver={snapshot.isDraggingOver}
            >
              {tagsToSelect.map((t, index) => (
                <Draggable key={t.id} index={index} draggableId={`${t.id}`}>
                  {(draggableProvided, snapshot) => (
                    <span
                      className={cx(styles.tag, {
                        [styles.tag__selected]: t === selectedTag,
                        [styles.tag__nonSelected]: selectedTag && t !== selectedTag,
                      })}
                      ref={draggableProvided.innerRef}
                      onClick={() => setSelectedTag((prev) => (prev === t ? undefined : t))}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {t.text}
                    </span>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <span />
            </Flex>
          )}
        </Droppable>

        <Space direction={"vertical"} className={styles.sentences}>
          {sentences.map((s) =>
            s.text ? (
              <Droppable key={`${s.id}`} droppableId={`${s.id}`} direction={"horizontal"} isDropDisabled={isCompleted}>
                {(provided, snapshot) => (
                  <div
                    className={cx(styles.droppableSentence, {
                      [styles.droppableSentence__wrong]: wrongTags.includes(s),
                      [styles.droppableSentence__hover]: selectedTag,
                      [styles.droppableSentence__hovered]: snapshot.isDraggingOver,
                      [styles.droppableSentence__filled]: dropTags[`${s.id}`] && !snapshot.draggingFromThisWith,
                    })}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    onClick={() => onDropClick(`${s.id}`)}
                  >
                    {dropTags[`${s.id}`] ? (
                      <Draggable
                        // isDragDisabled={dropTags[`${s.id}`]?.text === s.text}
                        index={s.id}
                        // @ts-ignore
                        key={dropTags[`${s.id}`].draggableId}
                        // @ts-ignore
                        draggableId={dropTags[`${s.id}`].draggableId}
                        //isDragDisabled={isCompleted}
                      >
                        {(draggableProvided, snapshot) => (
                          <span
                            ref={draggableProvided.innerRef}
                            onClick={(e) => {
                              e.stopPropagation();
                              const sent = sentences.find((sent) => `${sent.id}` === dropTags[`${s.id}`]?.draggableId);
                              setSelectedTag((prev) => (prev === sent ? undefined : sent));
                            }}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <span
                              className={cx(styles.tag, {
                                [styles.tag__selected]: dropTags[`${s.id}`]?.draggableId === `${selectedTag?.id}`,
                                [styles.tag__wrong]: !snapshot.isDragging && dropTags[`${s.id}`]?.text !== s.text,
                              })}
                            >
                              {dropTags[`${s.id}`]?.text}
                            </span>
                          </span>
                        )}
                      </Draggable>
                    ) : undefined}

                    {dropTags[`${s.id}`] && !snapshot.draggingOverWith && !snapshot.draggingFromThisWith
                      ? null
                      : showTranslate && s.translate}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ) : (
              <p />
            ),
          )}
        </Space>
      </DragDropContext>
      {contextHolder}
    </div>
  );
});

export default StorySentenceDragDrop;
