import { Button } from "antd";
import { StatusType } from "App.types";
import { BorderOutlined, SoundFilled } from "@ant-design/icons";
import React, { FC, useCallback, useEffect, useState } from "react";

type Props = {
  audio?: HTMLAudioElement;
};

const AudioButton: FC<Props> = ({ audio }) => {
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (audio) {
      audio.onended = () => setStatus("");
    }
  }, [audio]);

  const onPlay = useCallback(() => {
    if (status === StatusType.isPlaying && audio) {
      audio.pause();
      audio.currentTime = 0;
      setStatus(StatusType.Empty);
    } else {
      setStatus(StatusType.isPlaying);
      audio?.play();
    }
  }, [audio, status]);

  return <Button type={"primary"} icon={status === StatusType.isPlaying ? <BorderOutlined /> : <SoundFilled />} onClick={onPlay} />;
};

export default AudioButton;
