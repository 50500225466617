import React from "react";
import { Card, Col, Breadcrumb, Badge, Row } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./Stories.module.scss";
import API from "Api";
import { useQuery } from "@tanstack/react-query";
import { Levels, STATIC_URL } from "App.constants";

const Stories = () => {
  const { data: stories } = useQuery({ queryKey: ["stories"], queryFn: () => API.story.getList("story"), staleTime: 60 * 60 * 1000 });

  const navigate = useNavigate();

  return (
    <div className={styles.stories}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/stories")}>Stories</Breadcrumb.Item>
      </Breadcrumb>

      <Row gutter={{ xs: 12, sm: 24, md: 36, lg: 36 }}>
        {stories?.map(({ title, level = 0, author, id }) => (
          <Col key={id} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            <Badge.Ribbon text={Levels[level].title} color={Levels[level].color}>
              <Card
                className={styles.card}
                onClick={() => navigate(`${title}`)}
                hoverable
                cover={
                  <img
                    onError={(e: any) => (e.target.style.display = "none")}
                    alt="example"
                    src={`${STATIC_URL}/stories/${title}/images/0.jpg`}
                  />
                }
              >
                <Card.Meta title={title} description={author} />
              </Card>
            </Badge.Ribbon>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Stories;
