import { createClient, DeepgramClient, LiveClient } from "@deepgram/sdk";
import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useRef, useState } from "react";
import API from "Api";
import { StatusType, TagType } from "App.types";
import { uniq } from "lodash";
import { UserContext } from "App";
import { ignoreTags } from "App.constants";

type Props = {
  tags?: Partial<TagType>[];
  setStatus: Dispatch<SetStateAction<StatusType>>;
  interim_results?: boolean;
  smart_format?: boolean;
  model?: "enhanced" | "nova-2" | "nova" | undefined;
  withAllHints?: boolean;
};

type WordType = { confidence: number; word: string; punctuated_word: string; start: number; end: number };

export type ResultType = {
  transcript: string;
  confidence: number;
  words: WordType[];
};

const useDeepgramAlt = ({
  setStatus,
  tags = [],
  smart_format = true,
  interim_results = false,
  model = "nova",
  withAllHints,
}: Props): {
  start: Function;
  stop: Function;
  reset: Function;
  results: ResultType[];
  isSpeaking: boolean;
} => {
  const [results, setResults] = useState<ResultType[]>([]);
  // const [wordResults, setWordResults] = useState<{ [start: string]: WordType & { isFinal: boolean } }>({});
  const [token, setToken] = useState<any>();
  const [isSpeaking, setIsSpeaking] = useState(false);

  const userMedia = useRef<MediaStream>();
  const socket = useRef<LiveClient>();
  const microphone = useRef<MediaRecorder>();

  const client = useRef<DeepgramClient>();
  const hasError = useRef(false);

  const lastItem = useRef<{ last: number; isFinal: boolean | undefined }>({ last: -1, isFinal: true });

  const user = useContext(UserContext);

  const timeoutId = useRef<any>(null);

  const defaultKeywords = tags.filter((t) => !ignoreTags.includes(t.lemma)).map((t) => `${t.word}`);

  const keywords = useMemo(
    () =>
      tags.some((t) => t.isHint)
        ? uniq(tags?.filter((t) => t.isHint || t.pos?.includes("NNP")).map((t) => `${t.word}:2`))
        : withAllHints
          ? defaultKeywords
          : [],
    [defaultKeywords, tags, withAllHints],
  );

  const start = useCallback(
    async (key = "") => {
      lastItem.current = { last: -1, isFinal: true };

      setStatus(StatusType.Loading);
      setResults([]);

      if (key) {
        client.current = await createClient(key);
      } else {
        const newToken = token || (await API.user.getDGToken());
        setToken(newToken);
        client.current = await createClient(newToken.key);
      }

      if (!socket.current || socket.current?.getReadyState() !== 1) {
        socket.current = client.current.listen.live({
          model,
          language: user?.isEnglish ? "en" : "de-DE",
          smart_format,
          interim_results,
          alternatives: model === "nova-2" ? 1 : 5,
          vad_events: true,
          // search: tags?.map((t) => t.word).join(" "),

          // @ts-ignore
          keywords,
        });

        socket.current.on("SpeechStarted", () => {
          setIsSpeaking(true);
          clearTimeout(timeoutId.current);
          timeoutId.current = setTimeout(() => setIsSpeaking(false), 1000);
        });

        socket.current?.on("error", async (e) => {
          console.error("error", e);
          microphone.current?.stop();

          setToken(null);

          if (!hasError.current) {
            hasError.current = true;
            const newToken = await API.user.getDGToken();
            setToken(newToken);
            start(newToken.key);
          }
        });

        socket.current.on("open", async () => {
          userMedia.current = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });

          if (!userMedia) return console.error("no UserMedia available");

          console.log("client: connected to websocket");
          microphone.current = new MediaRecorder(userMedia.current);

          microphone.current.ondataavailable = (e) => {
            // console.log("voice: sent data to websocket");
            socket.current?.send(e.data);
          };

          microphone.current?.start(500);
          setStatus(StatusType.IsRecording);
          hasError.current = false;
        });

        socket.current?.on("Results", (data) => {
          const alternatives = data.channel.alternatives.filter((a: any) => a.confidence > 0.1);

          alternatives?.length && console.log(alternatives.map((a: any) => a.transcript));
          setResults(alternatives);

          // setResults((prevResults) => {
          //   const lastText = last(prevResults);
          //
          //   if (data.start <= lastItem.current.last && !lastItem.current.isFinal) return [];
          //
          //   return lastText?.start === data.start
          //     ? [...take(prevResults, prevResults.length - 1), { texts: alternatives, start: data.start, isFinal: data.is_final }]
          //     : [...prevResults, { texts: alternatives, start: data.start, isFinal: data.is_final }];
          // });

          // setWordResults((prev) => {
          //   dataWords.forEach((wordResult: WordType) => {
          //     if (data.is_final) {
          //       keys(prev).forEach((k) => {
          //         if (!prev[k]?.isFinal) {
          //           delete prev[k];
          //         }
          //       });
          //     }
          //     prev[wordResult.start] = { ...wordResult, isFinal: data.is_final };
          //   });
          //   return { ...prev };
          // });
        });

        socket.current?.on("warning", (e) => console.warn(e));

        socket.current?.on("Metadata", (e) => console.log(e));

        socket.current?.on("close", () => {
          microphone.current?.stop();
          setStatus((prevState) => (prevState !== StatusType.Completed ? StatusType.Empty : prevState));
        });
      }
    },
    [setStatus, token, model, user?.isEnglish, smart_format, interim_results, keywords],
  );

  const stop = useCallback(() => {
    microphone.current?.stop();
    microphone.current = undefined;
    if (userMedia.current) userMedia?.current.getTracks().forEach((track) => track.stop());
    console.log("stop mic");

    socket.current?.disconnect();
  }, [userMedia]);

  const reset = useCallback(() => {
    // @ts-ignore
    // setResults((prev) => (last(prev) && !last(prev).isFinal ? [{ text: "", start: last(prev)?.start, destroy: true }] : []));

    setWordResults({});

    setResults(() => {
      // lastItem.current = { last: last(prev)?.start ?? -1, isFinal: last(prev)?.isFinal };
      return [];
    });
  }, []);

  return { start, stop, results, reset, isSpeaking };
};

export default useDeepgramAlt;
