import { SentenceType } from "App.types";
import { useContext, useMemo } from "react";
import { UserContext } from "App";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { replaceTemplates } from "App.helpers";

const useTemplatedSentences = (sentences: SentenceType[]) => {
  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  return useMemo(
    () =>
      sentences.map((s) => ({
        ...s,
        alternatives: s.alternatives.map((alt) => ({
          ...alt,
          text: replaceTemplates(alt.text, userProps),
          translate: replaceTemplates(alt.translate, userProps),
          tags: alt.tags?.map((tag) => ({ ...tag, word: replaceTemplates(tag.word, userProps) })),
        })),
        text: replaceTemplates(s.text, userProps) ?? "",
        translate: replaceTemplates(s.translate, userProps) ?? "",
        tags: s.tags.map((tag) => ({ ...tag, word: replaceTemplates(tag.word, userProps) })),
        hint: replaceTemplates(s.hint, userProps),
        info: replaceTemplates(s.info, userProps),
      })),
    [sentences, userProps],
  );
};

export default useTemplatedSentences;
