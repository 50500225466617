import { Drawer, List } from "antd";
import React, { FC } from "react";
import styles from "./Dictionary.module.scss";
import useDictionary from "Components/useDictionary";
import { SentenceType } from "App.types";
import DictionaryItem from "./DictionaryItem";

const Dictionary: FC<{ storyId?: number | string; sentences?: SentenceType[] | SentenceType; isOpen: boolean; toggle: Function }> = ({
  sentences,
  isOpen,
  toggle,
  storyId = "",
}) => {
  const dictionary = useDictionary(sentences, isOpen);

  return (
    <Drawer title="слова задания" placement="right" onClose={() => toggle(false)} open={isOpen}>
      <List
        size={"small"}
        className={styles.dictionary}
        dataSource={dictionary}
        renderItem={(item) => <DictionaryItem storyId={storyId} item={item} />}
      />
    </Drawer>
  );
};

export default React.memo(Dictionary);
