import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { SentenceType } from "App.types";
import { Col, notification, Row } from "antd";
import cx from "classnames";
import { shuffle, sortBy } from "lodash";
import { isMobile } from "react-device-detect";
import styles from "./StoryMatchTask.module.scss";
import { successMessage } from "App.constants";
import { useAutoAnimate } from "@formkit/auto-animate/react";

type Props = {
  sentences: SentenceType[];
  onTaskComplete: () => void;
  isCompleted: boolean;
};

const StoryMatchTask: FC<Props> = ({ isCompleted, sentences, onTaskComplete }) => {
  const [rightAnswers, setRightAnswers] = useState<number[]>([]);
  const [wrongAnswer, setWrongAnswer] = useState<string>();
  const [selectedText, setSelectedText] = useState<SentenceType>();
  const [selectedTranslate, setSelectedTranslate] = useState<SentenceType>();

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const shuffledTexts = useMemo(() => shuffle(sentences), [sentences]);
  const shuffledTranslates = useMemo(() => shuffle(sentences), [sentences]);

  const texts = useMemo(
    () =>
      sortBy(
        shuffledTexts.map((s, idx) => ({ ...s, idx, completed: rightAnswers.includes(s.id) })),
        (s) => (s.completed ? s.id : s.idx),
      ),
    [rightAnswers, shuffledTexts],
  );
  const translates = useMemo(
    () =>
      sortBy(
        shuffledTranslates.map((s, idx) => ({ ...s, idx, completed: rightAnswers.includes(s.id) })),
        (s) => (s.completed ? s.id : s.idx),
      ),
    [rightAnswers, shuffledTranslates],
  );

  const onTextClick = useCallback(
    (s: SentenceType) => {
      setSelectedText((prev) => (prev?.id === s.id ? undefined : s));

      if (selectedTranslate) {
        if (s.id === selectedTranslate?.id) {
          setRightAnswers((prev) => [...prev, s.id]);
          setSelectedTranslate(undefined);
          setWrongAnswer(undefined);
        } else {
          setWrongAnswer(s.text);
        }
        setSelectedText(undefined);
      } else {
        setWrongAnswer(undefined);
      }
    },
    [selectedTranslate],
  );

  const onTranslateClick = useCallback(
    (s: SentenceType) => {
      setSelectedTranslate((prev) => (prev?.id === s.id ? undefined : s));

      if (selectedText) {
        if (s.id === selectedText?.id) {
          setRightAnswers((prev) => [...prev, s.id]);
          setSelectedText(undefined);
          setWrongAnswer(undefined);
        } else {
          setWrongAnswer(s.translate);
        }
        setSelectedTranslate(undefined);
      } else {
        setWrongAnswer(undefined);
      }
    },
    [selectedText],
  );

  useEffect(() => {
    if (!isCompleted && rightAnswers.length === sentences.length) {
      notifyApi.success(successMessage);
      onTaskComplete();
    }
  }, [isCompleted, notifyApi, onTaskComplete, rightAnswers.length, sentences.length]);

  const [textsRef] = useAutoAnimate();
  const [translatesRef] = useAutoAnimate();

  return (
    <div className={styles.storyMatchTask}>
      <div className={cx(styles.content, styles.content_autoHeight)}>
        <Row gutter={10}>
          <Col span={12} ref={textsRef}>
            {texts?.map((s) => (
              <div
                key={s.id}
                className={cx(styles.button, styles.button__left, {
                  [styles.button__mobile]: isMobile,
                  [styles.button__active]: selectedText?.id === s.id,
                  [styles.button__disabled]: s.completed,
                  [styles.button__correct]: s.completed,
                  [styles.button__wrong]: wrongAnswer === s.text,
                })}
                onClick={() => onTextClick(s)}
              >
                {s.text}
              </div>
            ))}
          </Col>

          <Col span={12} ref={translatesRef}>
            {translates?.map((s) => (
              <div
                key={s.id}
                className={cx(styles.button, {
                  [styles.button__mobile]: isMobile,
                  [styles.button__active]: selectedTranslate?.id === s.id,
                  [styles.button__disabled]: s.completed,
                  [styles.button__correct]: s.completed,
                  [styles.button__wrong]: wrongAnswer === s.translate,
                })}
                onClick={() => onTranslateClick(s)}
              >
                {s.translate}
              </div>
            ))}
          </Col>
        </Row>

        <div className={styles.bottom}></div>
      </div>

      {contextHolder}
    </div>
  );
};

export default StoryMatchTask;
