import ReactPlayer from "react-player";
import React, { MouseEventHandler, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Button, Skeleton, Tabs, TabsProps, theme } from "antd";
import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./VideoPage.module.scss";
import { OnProgressProps } from "react-player/base";
import { delay, findIndex, groupBy } from "lodash";
import { scroller, Element } from "react-scroll";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import { CloseOutlined } from "@ant-design/icons";
import VideoSentence from "./VideoSentence";
import StickyBox from "react-sticky-box";
import { STATIC_URL } from "App.constants";

const VideoPage = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeWordIdx, setActiveWordIdx] = useState<number | null>(null);
  const [activeSentence, setActiveSentence] = useState<number>();
  const [end, setEnd] = useState<number>();
  const [activeTab, setActiveTab] = useState("0");

  const [searchParams] = useSearchParams();
  const title = searchParams.get("title") || "";

  const { data: story } = useQuery({
    queryFn: () => API.story.getByTitle(title),
    enabled: !!title,
    queryKey: ["story", title],
    staleTime: Infinity,
  });

  const { data: sentences = [], isLoading } = useQuery({
    queryKey: ["sentences", story?.id],
    enabled: !!story?.id,
    queryFn: () => API.sentence.getListByStoryId(story?.id || 0),
    staleTime: Infinity,
  });

  const { data: transcripts } = useQuery({
    staleTime: Infinity,
    queryKey: ["transcripts", story?.id],
    enabled: !!story?.id,
    queryFn: () => API.transcriptions.getByStoryId(story?.id || 0),
  });

  const groupedTrans = useMemo(() => groupBy(transcripts, "sent"), [transcripts]);

  const navigate = useNavigate();

  const playerRef = useRef<ReactPlayer>(null);

  const playSentence = useCallback(
    (start: number, end: number) => {
      if (end) setEnd(end);
      console.log(start, end);
      playerRef?.current?.seekTo(start);
      setIsPlaying(true);
    },
    [playerRef],
  );

  const onProgress = ({ playedSeconds }: OnProgressProps) => {
    if (end && playedSeconds > end) {
      setIsPlaying(false);
      setEnd(undefined);
      setActiveWordIdx(null);
      return;
    }

    let activeTrans = transcripts?.find((tr) => tr.start <= playedSeconds && tr.end >= playedSeconds);
    if (!activeTrans) {
      activeTrans = transcripts?.find((tr) => tr.start >= playedSeconds);
    }

    const activeIdx = activeTrans ? sentences.findIndex((s) => s.id === activeTrans?.sent) : -1;

    if (activeIdx > -1) {
      setActiveSentence(activeIdx);

      // carouselRef?.current?.goTo(activeIdx < 4 ? 0 : round(activeIdx / 3));

      const wordIdx = findIndex(groupedTrans[activeTrans?.sent || 0], (el) => el.end >= playedSeconds);
      setActiveWordIdx(wordIdx > -1 ? wordIdx : wordIdx + 1);
    }
  };

  useLayoutEffect(() => {
    delay(
      () => scroller.scrollTo(`${activeTab}${activeSentence}`, { offset: -55, smooth: true, duration: 500, containerId: "transcript" }),
      0,
    );
  }, [activeTab, activeSentence]);

  const onClose: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();

    navigate("/");
  };

  const onPause = () => {
    setIsPlaying(false);
    setActiveWordIdx(null);
  };

  // console.log(activeSentence, sentencesToDisplay);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => (
    <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 1 }}>
      <DefaultTabBar {...props} style={{ background: colorBgContainer }} />
    </StickyBox>
  );

  const onSentenceClick = useCallback((idx: number) => {
    setActiveSentence(idx);
    setIsPlaying(false);
  }, []);

  return (
    <div className={cx(styles.videoPage, { [styles.videoPage_mobile]: isMobile })}>
      <div className={styles.player}>
        <ReactPlayer
          controls={true}
          onSeek={() => setIsPlaying(true)}
          ref={playerRef}
          progressInterval={200}
          onProgress={isPlaying ? onProgress : undefined}
          onPlay={() => setIsPlaying(true)}
          onError={() => setIsPlaying(false)}
          onPause={onPause}
          playing={isPlaying}
          width="100%"
          // config={{ embedOptions: { frameborder: "0" }, playerVars: { frameborder: "0" } }}
          height="100%"
          url={story?.description?.includes("http") ? story?.description : `${STATIC_URL}/stories/${title}/video.mp4`}
        />
        <Button type={"link"} size={"large"} onClick={onClose} className={styles.btnClose} icon={<CloseOutlined size={28} />} />
      </div>

      {/*<div>*/}
      {/*  <Segmented onChange={(v) => setSource(v as string)} options={["Оригинал", "Перевод", "Все вместе"]} />*/}
      {/*</div>*/}

      {isLoading ? (
        <Skeleton className={styles.skeleton} loading active />
      ) : (
        <div className={styles.transcript} id={"transcript"}>
          <Tabs
            onChange={setActiveTab}
            size={"small"}
            renderTabBar={renderTabBar}
            items={[
              {
                key: "0",
                label: "Оригинал",
                children: (
                  <>
                    {sentences.map((sentence, idx) => (
                      <Element key={sentence.id} name={`0${idx}`} className="element">
                        <VideoSentence
                          idx={idx}
                          showHovered={!isPlaying && activeSentence === idx}
                          // @ts-ignore
                          transcripts={groupedTrans?.[sentence.id]}
                          onClick={onSentenceClick}
                          activeWordIdx={activeSentence === idx ? activeWordIdx : null}
                          active={activeSentence === idx}
                          play={playSentence}
                          sentence={sentence}
                          inActive
                          // wrap={idx === 0 || sentences[idx - 1].wrap}
                          wrap
                        />
                      </Element>
                    ))}
                  </>
                ),
              },
              {
                key: "1",
                label: "Перевод",
                children: (
                  <>
                    {sentences.map((sentence, idx) => (
                      <Element key={sentence.id} name={`1${idx}`} className="element">
                        <VideoSentence
                          idx={idx}
                          // @ts-ignore
                          transcripts={groupedTrans?.[sentence.id]}
                          onClick={setActiveSentence}
                          activeWordIdx={activeSentence === idx ? activeWordIdx : null}
                          active={activeSentence === idx}
                          play={playSentence}
                          sentence={sentence}
                          showTranslate
                          inActive
                          // wrap={idx === 0 || sentences[idx - 1].wrap}
                          wrap
                        />
                      </Element>
                    ))}
                  </>
                ),
              },
              {
                key: "2",
                label: "Все вместе",
                children: (
                  <>
                    {sentences.map((sentence, idx) => (
                      <Element key={sentence.id} name={`2${idx}`} className="element">
                        <VideoSentence
                          idx={idx}
                          // @ts-ignore
                          transcripts={groupedTrans?.[sentence.id]}
                          onClick={setActiveSentence}
                          activeWordIdx={activeSentence === idx ? activeWordIdx : null}
                          active={activeSentence === idx}
                          play={playSentence}
                          sentence={sentence}
                          withTranslate
                          inActive={false}
                          // wrap={idx === 0 || sentences[idx - 1].wrap}
                          wrap
                        />
                      </Element>
                    ))}
                  </>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default VideoPage;
