import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { StatusType, TaskType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { ignoreTags, SkipTags } from "App.constants";
import { Button, Popconfirm } from "antd";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { SoundFilled } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import { useSpeechCheck } from "Hooks/useSpeechCheck";
import { last } from "lodash";
import useDeepgram from "Hooks/useDeepgram";
import TaskPanel from "Components/TaskPanel";
import RecordButton from "Components/RecordButton";

const initialValue = [{ children: [{ text: "" }] }];

const VoiceTranslateTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { text, tags = [], translate, id, storyId },
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  showGrammar,
  alignCenter = false,
  transcripts,
  lesson,
  noTranslate,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const {
    start,
    stop,
    transcript: textFromSpeech,
    isSpeaking,
  } = useDeepgram({ tags, setStatus, interim_results: true, smart_format: false });

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];
    Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [editor, tags, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  useEffect(() => {
    if (!textFromSpeech) {
      setCompletedTagIdx(-1);
    }
  }, [textFromSpeech]);

  // audio transcript
  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        placeholder
        hidden={status !== StatusType.Completed && props.leaf.idx >= completedTagIdx + 1}
        underlined={props.leaf.audioIdx === activeLeaf}
        wrong={status === "error" && props.leaf.idx === (activeLeaf ?? 0) + 1 && !ignoreTags.includes(props.leaf.text)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, completedTagIdx, activeLeaf],
  );

  const filteredRightTags = useMemo(
    () => getCompletedSlate(tags, text).filter((el) => el.word && !SkipTags.includes(el.word)),
    [tags, text],
  );

  useEffect(() => {
    if (completedTagIdx >= last<any>(filteredRightTags)?.idx) {
      setStatus(StatusType.Completed);
      onTaskComplete();
      // setActiveSpeechIdx(-1);
    }
  }, [completedTagIdx, filteredRightTags, onTaskComplete]);

  useSpeechCheck({ status, sentence, setCompletedTagIdx, textFromSpeech, completedTagIdx, filteredRightTags });

  const onRecordClick = () => {
    if (status === StatusType.IsRecording) {
      stop();
    } else {
      setCompletedTagIdx(-1);
      setStatus(StatusType.Loading);
      start();
    }
  };

  const onSkip = useCallback(() => {
    onTaskComplete().then(() => onNext());
  }, [onNext, onTaskComplete]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <Slate editor={editor} initialValue={initialValue}>
              <Editable
                className={styles.textArea}
                readOnly
                onKeyDown={() => setStatus(StatusType.Editing)}
                renderLeaf={renderLeaf}
                //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
              />
            </Slate>
          </div>
          {(!noTranslate || status === StatusType.Completed) && <div className={styles.translate}>{translate}</div>}
        </div>

        <div className={styles.bottom}>
          <Button
            style={{ visibility: status !== StatusType.Completed ? "hidden" : undefined }}
            icon={<SoundFilled />}
            onClick={() => play?.() || audio?.play()}
          />
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.VoiceTranslate}
        sentId={id}
        storyId={storyId}
        setDictOpened={setDictOpened}
        isCompleted={status === StatusType.Completed}
        onNext={onNext}
        showGrammar={showGrammar}
        audio={audio}
      >
        <span className={styles.buttons}>
          <RecordButton
            onClick={onRecordClick}
            isRecording={status === "isRecording"}
            loading={status === "loading"}
            isSpeaking={isSpeaking}
          />
          <Popconfirm title={"Не можешь говорить?"} onConfirm={onSkip}>
            <Button className={styles.btn_skip} size={"small"} type={"text"}>
              skip
            </Button>
          </Popconfirm>
        </span>
      </TaskPanel>
    </div>
  );
};

export default VoiceTranslateTask;
