import { useMemo } from "react";
import { SentenceType, TagType } from "App.types";
import { TagsToMerge } from "App.constants";
import { isArray } from "lodash";

const useMergedTags = (sentences: SentenceType[] | SentenceType) => {
  return useMemo(
    () =>
      (isArray(sentences) ? sentences : [sentences]).map((s) => {
        return {
          ...s,
          tags: s.tags.reduce<TagType[]>((acc, tag, idx) => {
            if (
              s.phrases.some((ph) => ph.positionsIdx.findIndex((el) => el === idx) > 0) ||
              (TagsToMerge.includes(tag.word) && acc[acc.length - 1])
            ) {
              acc[acc.length - 1] = {
                ...acc[acc.length - 1],
                word: [acc[acc.length - 1].word, tag.word].join(s.phrases.some((ph) => ph.positionsIdx.includes(idx)) ? " " : ""),
              };
              return [...acc];
            } else {
              return [...acc, tag];
            }
          }, []),
        };
      }),
    [sentences],
  );
};

export default useMergedTags;
