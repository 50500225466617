import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { API_URL, GERMAN_VOICES, SkipTags, STATIC_URL } from "App.constants";
import { delay, findIndex, flatten, last } from "lodash";
import { Button, Flex, Progress, Segmented } from "antd";
import { useParams } from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import styles from "./Story.module.scss";
import { ProgressType, StatusType, TaskType } from "App.types";
import { TaskComponents } from "Components/SentenceTask";
import StorySentence from "Components/StorySentence";
import ButtonClose from "Components/ButtonClose";
import Dictionary from "Components/Dictionary";
import SentenceComments from "Components/SentenceComments";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import useProgress from "Hooks/useProgress";
import CompleteWindow from "Components/CompleteWindow";
import { PauseOutlined, SoundFilled } from "@ant-design/icons";
import useDeepgram from "Hooks/useDeepgram";
import { getCompletedSlate, isEqualEnglishWords, isEqualText } from "App.helpers";
import wordsToNumbers from "words-to-numbers";
import { UserContext } from "App";
import RecordButton from "Components/RecordButton";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";
import TaskPanelBasic from "Components/TaskPanelBasic";

const Story: FC<{}> = () => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [isStoryAudio, setStoryAudio] = useState<boolean>(true);
  const [source, setSource] = useState<string>("");
  const [selectedSent, setSelectedSent] = useState<number>();
  const [activeSentIdx, setActiveSentIdx] = useState<number>(0);
  const [activeWordIdx, setActiveWordIdx] = useState<number | undefined>(undefined);
  const [percent, setPercent] = useState<number>(0);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [isDictOpened, setDictOpened] = useState(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [tries, setTries] = useState<number>(1);
  const [completedSpeech, setCompletedSpeech] = useState("");
  // const [showNext, setShowNext] = useState(true);

  const { id = "", course } = useParams();

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  const {
    story: { title, type, id: storyId },
  } = lesson || { story: {} };

  const { data: sentences = [] } = useSuspenseQuery({
    staleTime: Infinity,
    // enabled: !!storyId,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  const allTags = useMemo(() => flatten(sentences.map((s) => s.tags)), [sentences]);

  const { start, stop, words, reset, transcript: speechText } = useDeepgram({ setStatus, tags: allTags });

  const sentence = useMemo(() => sentences[activeSentIdx], [sentences, activeSentIdx]);

  const activeType = useMemo(() => sentence.tasks[0], [sentence]);

  const cleanedText = useMemo(() => sentence?.text.replace(/[^a-zа-яё0-9]/gi, "").toLowerCase(), [sentence?.text]);
  const cleanedSpeechText = useMemo(() => speechText.replace(/[^a-zа-яё0-9]/gi, "").toLowerCase(), [speechText]);

  const onTaskComplete = useCallback(
    (sentId: number, defPercent: number = 0) => {
      const length = sentences.length;
      const step = 100 / length;

      setPercent((prevState = defPercent) => {
        let nextPercent = prevState + step >= length * step ? 100 : prevState + step;

        if (nextPercent > 100 - step + 1) {
          nextPercent = 100;
          stop();

          // API.progress.save({ lesson: { id: +id }, status: "completed", percent: nextPercent, score });
          API.progress.save({
            sentence: { id: sentId },
            status: "progress",
            lesson: { id: +id },
            type: activeType,
            percent: nextPercent,
            tries,
          });
        } else {
          API.progress.save({ sentence: { id: sentId }, lesson: { id: +id }, type: activeType, percent: nextPercent, tries });
        }

        return nextPercent;
      });
    },
    [sentences.length, id, activeType, tries, stop],
  );

  const filteredRightTags = useMemo(
    () => (sentence ? getCompletedSlate(sentence.tags, sentence.text).filter((el) => el.word && !SkipTags.includes(el.word)) : []),
    [sentence],
  );

  useEffect(() => {
    if (status === StatusType.IsRecording && (activeWordIdx ?? -1) >= filteredRightTags.length) {
      setCompletedSpeech("");
      onTaskComplete(sentence.id);
      reset();

      delay(() => {
        setActiveWordIdx(undefined);
        setActiveSentIdx((prevState) => prevState + 1);
      }, 1000);
    }
  }, [activeWordIdx, filteredRightTags.length, onTaskComplete, reset, sentence?.id, status]);

  // speech
  useEffect(() => {
    if (!cleanedSpeechText || !cleanedText || !sentence || (activeWordIdx ?? 0) >= filteredRightTags.length) return;

    if (isEqualText(cleanedSpeechText, cleanedText) || cleanedSpeechText.includes(cleanedText)) {
      setActiveWordIdx(Infinity);
    } else {
      // let index = 0;
      const spWords = speechText.replace(completedSpeech, "").trim().split(" ");

      spWords.some((_, idx) => {
        return spWords.slice(idx, spWords.length).every((word, wordIdx) => {
          const activeWord = filteredRightTags[(activeWordIdx ?? 0) + wordIdx]?.word || "";

          if (isEqualEnglishWords(word, activeWord) || isEqualEnglishWords(`${wordsToNumbers(word)}`, `${wordsToNumbers(activeWord)}`)) {
            setActiveWordIdx((prevState = 0) => prevState + 1);
            return true;
          }
          return false;
        });
      });

      setCompletedSpeech(speechText);
    }
  }, [
    activeWordIdx,
    onTaskComplete,
    sentence,
    cleanedSpeechText,
    cleanedText,
    words,
    reset,
    speechText,
    completedSpeech,
    filteredRightTags,
  ]);

  const onClickRecording = () => {
    if ([StatusType.IsRecording, StatusType.Loading].includes(status)) {
      stop();
      setActiveWordIdx(0);
    } else {
      setCompletedSpeech("");
      setStatus(StatusType.Loading);
      // setActiveWordIdx(0);
      setActiveSentIdx((prevState) => (prevState > 0 ? prevState : 0));
      start();
    }
  };

  const onRepeat = useCallback(() => {
    API.progress.delete(id);
    setPercent(0);
    setActiveSentIdx(0);
    setSelectedSent(-1);
    setStatus(StatusType.Empty);
  }, [id]);

  const onContinue = useCallback(
    (progress: ProgressType) => {
      setPercent(progress.percent);
      const sentIdx = sentences.findIndex((s) => s.id === progress.sentence.id);
      // @ts-ignore
      setActiveType(progress.type);
      setActiveSentIdx(sentIdx + 1);
    },
    [sentences],
  );

  const { contextHolder, showProgressModal } = useProgress({ lesson, onRepeat, onContinue });

  const completedSentences = useMemo(
    () => ([TaskType.Read, TaskType.Listen].includes(activeType) ? sentences : sentences.slice(0, activeSentIdx)),
    [activeSentIdx, sentences, activeType],
  );

  const audio = useMemo(() => {
    if (title) {
      const aud = new Audio(`${STATIC_URL}/stories/${title}/audio.m4a`);
      aud.onerror = (e: any) =>
        e.target.src.includes(".mp3") ? setStoryAudio(false) : (e.target.src = `${STATIC_URL}/stories/${title}/audio.mp3`);
      return aud;
    }
    return undefined;
  }, [title]);

  const user = useContext(UserContext);

  const audios = useMemo(() => {
    if (!isStoryAudio) {
      return sentences.map((s, idx) => {
        const aud = new Audio(
          user?.isEnglish
            ? `${API_URL}/speech/audio?text=${s.text}&lang=uk&story=${storyId}&neural=${idx % 2 === 0 ? "" : "true"}&rate=${
                lesson.speechRate || "slow"
              }`
            : `${API_URL}/speech/nonenglish?text=${s.text}&voice=${
                GERMAN_VOICES[idx % 2 || type !== "dialog" ? 1 : 0]
              }&story=${storyId}&rate=${lesson.speechRate || "slow"}`,
        );
        aud.onplay = () => setAudioStatus(StatusType.isPlaying);
        aud.onended = ({ type }) => {
          if (type === "ended")
            setActiveSentIdx((prev) => {
              if (sentences.length === prev + 1) {
                // onTaskComplete();
                setAudioStatus(StatusType.Empty);
                setPercent(100);
                //setStatus(StatusType.Completed);
              }
              return prev + 1;
            });
        };
        return aud;
      });
    }
  }, [isStoryAudio, lesson.speechRate, sentences, storyId, type, user?.isEnglish]);

  const activeAudio = useMemo(() => audios?.[activeSentIdx], [audios, activeSentIdx]);

  useEffect(() => {
    audio?.addEventListener("play", () => setAudioStatus(StatusType.isPlaying));
    audio?.addEventListener("pause", () => setAudioStatus(StatusType.Empty));
    audio?.addEventListener("ended", () => onTaskComplete(last(sentences)?.id || 0, 100));

    return () => audio?.pause();
  }, [audio, onTaskComplete, sentences]);

  useEffect(() => {
    // setActiveSentIdx(activeType === "listen" ? Infinity : 0);
    setStatus(StatusType.Empty);
  }, [activeType]);

  const timeListener = useRef<any>(null);

  const play = useCallback(
    (
      from: number = sentences[0].transcripts[0]?.start,
      to: number = last(last(sentences)?.transcripts)?.end || 0,
      playOne: boolean = false,
    ) => {
      if (!audio || audio?.networkState !== 1) {
        return;
      }

      audio.currentTime = !audio?.currentTime || audio.currentTime >= to || playOne ? from : audio.currentTime;
      audio?.play();

      audio.removeEventListener("timeupdate", timeListener.current);

      timeListener.current = ({ target: { paused } }: any) => {
        const { currentTime } = audio;

        if (currentTime >= to) {
          audio.pause();

          if (!playOne) {
            setPercent(100);
            setSelectedSent(-1);
          }
        }

        if (paused) {
          return delay(() => {
            setSelectedSent((prevState) => (playOne ? undefined : prevState));
            setActiveWordIdx(undefined);
          }, 500);
        }
        let activeIdx = sentences.findIndex(
          ({ transcripts }) => transcripts[0]?.start <= currentTime && (last(transcripts)?.end || 0) >= currentTime,
        );
        if (activeIdx === -1 && !playOne) {
          activeIdx = sentences.findIndex(({ transcripts }) => transcripts[0]?.start >= currentTime);
        }

        if (activeIdx > -1) {
          setSelectedSent(sentences[activeIdx]?.id);
          const wordIdx = findIndex(sentences[activeIdx].transcripts, (el) => el.end >= currentTime && !SkipTags.includes(el.text));
          setActiveWordIdx(wordIdx > -1 ? wordIdx : undefined);
        }
      };

      audio.addEventListener("timeupdate", timeListener.current);
    },
    [audio, sentences],
  );

  useEffect(() => {
    if (activeSentIdx === 0 && type === "dialog" && !activeAudio && status !== StatusType.Completed) {
      delay(() => play(), 1000);
    }
  }, [status, activeAudio, activeSentIdx, play, type]);

  useEffect(() => {
    if (activeAudio && status !== StatusType.Completed && activeType === "listen") {
      activeAudio.play();
    }
  }, [status, activeAudio, activeType]);

  const onNext = useCallback(() => {
    // setShowNext(false);
    setActiveSentIdx((prevState) => prevState + 1);
    if (percent === 100) {
      setStatus(StatusType.Completed);
    }

    delay(() => {
      // setShowNext(true);
    }, 500);

    // if (percent === 100) setStatus(StatusType.Completed);
  }, [percent]);

  const playSentence = useCallback(() => {
    const { transcripts } = sentence;
    play(transcripts[0].start, last(transcripts)?.end, true);
  }, [play, sentence]);

  const TaskComponent = TaskComponents[sentence?.tasks[0] || activeType];

  console.log(sentence);

  return (
    <div className={styles.story}>
      <Flex justify={"flex-end"} className={styles.story__top}>
        <Progress percent={percent} showInfo={false} />
        <ButtonClose path={`/course/${course}`} />
      </Flex>

      {status === StatusType.Completed ? (
        <CompleteWindow onRepeat={onRepeat} lessonId={+id} course={course} />
      ) : (
        <>
          {activeType !== TaskType.MaskedDragDrop && (
            <Segmented onChange={(v) => setSource(v as string)} options={["Оригинал", "Перевод", "Все вместе"]} />
          )}

          <div className={styles.story__title}>{title}</div>

          <div className={styles.story__text}>
            {!showProgressModal &&
              completedSentences.map((sentence, idx) => (
                <Fragment key={sentence.id}>
                  <StorySentence
                    storyId={id}
                    active={
                      selectedSent === sentence.id || (idx === activeSentIdx && [TaskType.Read, TaskType.Listen].includes(activeType))
                    }
                    activeWordIdx={selectedSent === sentence.id ? activeWordIdx : undefined}
                    completedWordIdx={
                      status === StatusType.IsRecording && activeType === TaskType.Read ? (activeWordIdx ?? 0) - 1 : undefined
                    }
                    play={play}
                    sentence={sentence}
                    marginLeft={sentences.some((s) => !s.wrap) ? idx === 0 || sentences[idx - 1].wrap : false}
                    showTranslate={source === "Перевод"}
                    withTranslate={source === "Все вместе"}
                    showText={["Оригинал", "Перевод"].includes(source)}
                  />
                  {completedSentences[idx]?.wrap && <div />}
                </Fragment>
              ))}

            {/*{!showProgressModal &&*/}
            {/*  type === "dialog" &&*/}
            {/*  completedSentences.map((sentence, idx) => (*/}
            {/*    <Fragment key={sentence.id}>*/}
            {/*      <StoryDialogSentence*/}
            {/*        audio={audios?.[idx]}*/}
            {/*        hidden={activeSentIdx < idx}*/}
            {/*        speechRate={lesson.speechRate}*/}
            {/*        storyId={id}*/}
            {/*        active={*/}
            {/*          selectedSent === sentence.id || (idx === activeSentIdx && [TaskType.Read, TaskType.Listen].includes(activeType))*/}
            {/*        }*/}
            {/*        activeWordIdx={selectedSent === sentence.id ? activeWordIdx : undefined}*/}
            {/*        sentence={sentence}*/}
            {/*        showTranslate={source === "Перевод"}*/}
            {/*        withTranslate={source === "Все вместе"}*/}
            {/*        showText={["Оригинал", "Перевод"].includes(source)}*/}
            {/*      />*/}

            {/*      {completedSentences[idx]?.wrap && <div />}*/}
            {/*    </Fragment>*/}
            {/*  ))}*/}

            <SwitchTransition>
              <CSSTransition key={`${activeSentIdx}${activeType}`} timeout={500} classNames={"fade"}>
                <>
                  {![TaskType.Read, TaskType.ReadByWord, TaskType.Listen].includes(activeType) && (
                    <TaskComponent
                      play={playSentence}
                      activeType={sentence.task || activeType}
                      lesson={lesson}
                      sentence={sentence}
                      onNext={onNext}
                      onTaskComplete={onTaskComplete}
                      audio={audio}
                      setDictOpened={setDictOpened}
                      showComments={setShowComments}
                      setTries={setTries}
                      autoHeight
                    />
                  )}

                  {[TaskType.Read, TaskType.ReadByWord, TaskType.Listen].includes(activeType) && (
                    <>
                      <div className={styles.placeholder} />
                      <TaskPanelBasic>
                        <PanelButtonPlaceholder />
                        {activeType === TaskType.Listen && (
                          <Button
                            //type={status === StatusType.Completed ? "default" : "primary"}
                            icon={audioStatus === StatusType.isPlaying ? <PauseOutlined /> : <SoundFilled />}
                            onClick={
                              audioStatus === StatusType.isPlaying
                                ? () => {
                                    audio?.pause();
                                    activeAudio?.pause();
                                    setAudioStatus(StatusType.Empty);
                                  }
                                : () => {
                                    if (activeSentIdx > sentences.length - 1) {
                                      setActiveSentIdx(0);
                                    }
                                    activeAudio?.play();
                                    play();
                                  }
                            }
                          />
                        )}

                        {[TaskType.Read, TaskType.ReadByWord].includes(activeType) && (
                          <RecordButton
                            disabled={percent === 100}
                            onClick={onClickRecording}
                            isRecording={status === StatusType.IsRecording}
                            loading={status === StatusType.Loading}
                          />
                        )}
                        <PanelButtonPlaceholder />
                      </TaskPanelBasic>
                    </>
                  )}
                </>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </>
      )}

      {contextHolder}
      <Dictionary storyId={id} isOpen={isDictOpened} sentences={sentences[activeSentIdx || 0]} toggle={setDictOpened} />
      <SentenceComments isOpen={showComments} toggle={setShowComments} sentence={sentences[activeSentIdx || 0]} />
    </div>
  );
};

export default Story;
