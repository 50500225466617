import { Button, Collapse, Form, Input, Modal, notification, Popconfirm, Space } from "antd";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import useScript from "Components/useScript";
import axios, { AxiosInstance } from "axios";
import { API_URL } from "App.constants";
import Cookies from "js-cookie";
import { useQueryClient } from "@tanstack/react-query";
import { isMobile } from "react-device-detect";
import AppleSignin from "react-apple-signin-auth";
import styles from "./LoginModal.module.scss";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import firebaseApp from "../../firebase";

const auth = getAuth(firebaseApp);

const isPWA = window.navigator.userAgent.includes("PWA");

const LoginModal: FC<{ axiosInstance: AxiosInstance; open: boolean; setUser: Function } & PropsWithChildren> = ({
  axiosInstance,
  open,
  setUser,
}) => {
  const [once, setOnce] = useState(true);
  const [isRegForm, setRegForm] = useState(false);
  const queryClient = useQueryClient();

  const [notify, contextHolder] = notification.useNotification();

  const handleLoginSuccess = useCallback(
    (token: string | undefined) => {
      if (!token) return;

      axios
        .get(`${API_URL}/users/afterLogin`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: false })
        .then(({ data }) => {
          setUser({ ...data, isEnglish: data.lang === "en" });
          axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;

          Cookies.set("token", token, { expires: 24 * 60 * 60 * 31 });
          Cookies.set("user", JSON.stringify({ ...data, isEnglish: data.lang === "en" }), { expires: 24 * 60 * 60 * 31 });
          queryClient.setDefaultOptions({ queries: { enabled: true } });
          queryClient.resetQueries({ fetchStatus: "idle" }).then();
        });
    },
    [axiosInstance, queryClient, setUser],
  );

  useGoogleOneTapLogin({
    onError: console.log,
    auto_select: true,
    cancel_on_tap_outside: false,
    onSuccess: (resp) => handleLoginSuccess(resp.credential),
    disabled: !open || isPWA,
    // promptMomentNotification: (moment) => setShowModal(!moment.isDisplayed()),
  });

  const initYandexLogin = useCallback(() => {
    if (!isPWA) {
      // @ts-ignore
      window.YaAuthSuggest.init(
        {
          client_id: "d18166ba3d9e417d9f9035d6bfd93bbf",
          response_type: "token",
          redirect_uri: `${window.location.origin}/yandex/`,
        },
        window.location.origin,
        {
          view: "button",
          parentId: "yandexButton",
          buttonSize: "m",
          buttonView: "additional",
          buttonTheme: "light",
          buttonBorderRadius: "5",
          buttonIcon: "ya",
        },
      )
        .then(({ handler }: any) => handler())
        .then((data: { access_token: string; expires_in: string }) => {
          handleLoginSuccess(data.access_token);
        })
        .catch((error: any) => console.log("Обработка ошибки", error));
    }
  }, [handleLoginSuccess]);

  useEffect(() => {
    if (open && !once) {
      initYandexLogin();
    }
  }, [open, once, initYandexLogin]);

  useScript("https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js", () => {
    if (open && once) {
      setOnce(false);
      initYandexLogin();
    }
  });

  const onRegister = useCallback(
    ({ email, password, name }: any) => {
      createUserWithEmailAndPassword(auth, email, password)
        .then((result) => {
          sendEmailVerification(result.user)
            .then(() => notify.success({ message: "Отлично, теперь подтверди адрес электронной почты!" }))
            .then(() => setRegForm(false));
        })
        .catch((result) =>
          notify.error({ message: result.code === "auth/email-already-in-use" ? "Пользователь уже существует" : result.code }),
        );
    },
    [notify],
  );

  const onPasswordLogin = useCallback(
    ({ email, password }: any) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
          if (!result.user.emailVerified) {
            notify.error({ message: "Confirm email address!" });
            sendEmailVerification(result.user).then();
          } else {
            result.user.getIdToken().then((token) => handleLoginSuccess(token));
          }
        })
        .catch((error) => {
          if (error.code.includes("auth/wrong-password")) {
            notify.error({ message: "wrong password!" });
          } else {
            notify.error({ message: error.description, description: error.code });
          }
        });
    },
    [handleLoginSuccess, notify],
  );

  const [loginForm] = Form.useForm();

  const resetPassword = useCallback(() => {
    const email = loginForm.getFieldValue("email");
    if (email.trim()) {
      sendPasswordResetEmail(auth, email).then(() => notify.success({ message: "Письмо отправлено на указанный адрес" }));
    } else {
      notify.error({ message: "Не указан адрес эл. почты!" });
    }
  }, [loginForm, notify]);

  return (
    <>
      <Modal
        closable={false}
        footer={
          <div style={{ textAlign: "left" }}>
            {!isPWA && (
              <>
                <GoogleLogin
                  width={"100%"}
                  size={"large"}
                  onSuccess={(resp) => handleLoginSuccess(resp.credential)}
                  useOneTap
                  cancel_on_tap_outside={false}
                />
                <div style={{ margin: "10px 0" }} id="yandexButton" />
              </>
            )}

            <AppleSignin
              className={styles.button}
              uiType={"light"}
              onSuccess={console.log}
              onError={console.error}
              authOptions={{
                usePopup: false,
                clientId: "lexoid.firebase",
                redirectURI: "https://api.lexoid.ru/users/apple",
                scope: "name email",
              }}
            />

            <Collapse
              size={"small"}
              ghost
              accordion
              items={[
                {
                  label: "По логину и паролю",
                  children: isRegForm ? (
                    <Form layout={"vertical"} onFinish={onRegister}>
                      <Form.Item name={"email"} label={"email"} rules={[{ required: true, message: "Please input your email!" }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={"name"} label={"name"} rules={[{ required: true, message: "Please input your name!" }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={"password"}
                        label={"password"}
                        rules={[
                          { min: 6, message: "Min length must be at least 6 characters" },
                          { required: true, message: "Please input password!" },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item
                        name={"passwordConfirm"}
                        dependencies={["password"]}
                        label={"confirm password"}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("The new password that you entered do not match!"));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Space>
                        <Button size={isMobile ? "middle" : undefined} type={"primary"} htmlType={"submit"}>
                          Register
                        </Button>
                        <Button size={isMobile ? "middle" : undefined} type={"dashed"} onClick={() => setRegForm(false)}>
                          Login
                        </Button>
                      </Space>
                    </Form>
                  ) : (
                    <Form layout={"vertical"} onFinish={onPasswordLogin} form={loginForm}>
                      <Form.Item name={"email"} label={"email"} rules={[{ required: true, message: "Please input your email!" }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={"password"} label={"password"} rules={[{ required: true, message: "Please input your password!" }]}>
                        <Input.Password />
                      </Form.Item>
                      <Space>
                        <Button type={"primary"} size={isMobile ? "middle" : undefined} htmlType={"submit"}>
                          Login
                        </Button>
                        <Button size={isMobile ? "middle" : undefined} type={"dashed"} onClick={() => setRegForm(true)}>
                          New user
                        </Button>
                        <Popconfirm title={"Выслать новый пароль на почту?"} onConfirm={resetPassword}>
                          <Button type={"link"} size={"small"} style={{ fontSize: 14 }}>
                            Сбросить
                          </Button>
                        </Popconfirm>
                      </Space>
                    </Form>
                  ),
                },
              ]}
            />
          </div>
        }
        open={open}
        title="Welcome to Lexoid!"
      >
        {"Для продолжения работы требуется авторизоваться"}
      </Modal>
      {contextHolder}
    </>
  );
};

export default LoginModal;
