import { Button, Progress, ProgressProps, Space, Timeline } from "antd";
import { ClockCircleOutlined, LockFilled } from "@ant-design/icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { GrammarType, ProgressType } from "App.types";
import cx from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TimelineItemProps } from "antd/es/timeline/TimelineItem";
import LessonsModule from "./LessonsModule";
import LessonsItem from "./LessonsItem";
import { useContext, useEffect, useMemo, useState } from "react";
import { getStepColor } from "./Lessons.conts";
import styles from "./Lessons.module.scss";
import GrammarPanel from "Components/GrammarPanel";
import { isMobile } from "react-device-detect";
import { STATIC_URL } from "App.constants";
import { findLast } from "lodash";
import { UserContext } from "App";
import { animateScroll } from "react-scroll";

const twoColors: ProgressProps["strokeColor"] = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const Lessons = () => {
  const [grammar, setGrammar] = useState<GrammarType | undefined>(undefined);
  const { course: courseTitle = "" } = useParams();

  const { state } = useLocation();

  useEffect(() => {
    if (state?.scrollPosition) {
      animateScroll.scrollTo(state.scrollPosition, { smooth: true });
    }
  }, [state?.scrollPosition]);

  const { data: lessons = [] } = useSuspenseQuery({
    queryKey: ["lessons", "course", courseTitle],
    queryFn: () => API.lesson.getList(courseTitle),
  });

  const { data: intros } = useSuspenseQuery({
    queryKey: ["intros", courseTitle],
    queryFn: () => API.sentence.getIntros(courseTitle),
    select: (data) => data.reduce((acc, sent) => ({ ...acc, [sent.storyId]: sent }), {}),
  });

  const { data: course } = useSuspenseQuery({
    queryKey: ["courses"],
    queryFn: API.course.getList,
    select: (data) => data.find((c) => c.title === courseTitle),
  });

  const user = useContext(UserContext);

  const rootLessons = useMemo(() => lessons.filter((l) => !l.moduleId), [lessons]);

  const lessonsOnly = useMemo(() => lessons.filter((l) => l.type !== "module" && !l.optional), [lessons]);

  const { data: progress } = useSuspenseQuery({
    queryKey: ["progress", courseTitle],
    queryFn: () => API.progress.getForCourse(courseTitle),
    select: (data) => data.reduce<{ [id: number]: ProgressType }>((acc, value) => ({ ...acc, [value?.lesson?.id || 0]: value }), {}),
  });

  const { data: allProgress = [] } = useSuspenseQuery({
    queryKey: ["progress", courseTitle],
    queryFn: () => API.progress.getForCourse(courseTitle),
  });

  const courseProgress = useMemo(
    () =>
      (allProgress.filter((pr) => lessons?.some((l) => l.id === pr.lesson.id) && pr.status === "completed").length / lessons.length) * 100,
    [allProgress, lessons],
  );

  const getStepIcon = (percent?: number) => {
    if (percent) {
      return <Progress status={percent !== 100 ? "exception" : undefined} type={"circle"} percent={percent} size={28} />;
    }

    return <ClockCircleOutlined style={{ fontSize: 28 }} />;
  };

  const navigate = useNavigate();

  const items: TimelineItemProps[] = rootLessons.map((lesson, idx) => {
    const isModule = lesson.type === "module";
    const childLessons = lessons.filter((l) => l.moduleId === lesson.id);
    const completedLessons = childLessons.filter((l) => progress?.[l.id]?.status === "completed").length;
    const modulePercent = isModule ? (1 - (childLessons.length - completedLessons) / childLessons.length) * 100 : 0;

    const isCompleted = isModule ? childLessons.length === completedLessons : progress?.[lesson.id]?.status === "completed";

    const prevLesson = findLast(lessonsOnly, (l) => l.order < lesson.order);
    const isLocked =
      !user?.isAdmin && prevLesson && progress?.[prevLesson.id]?.status !== "completed" && (progress?.[prevLesson.id]?.tries ?? 0) < 2;

    return {
      dot: isLocked ? (
        <LockFilled style={{ fontSize: 28, color: "lightgrey" }} />
      ) : (
        getStepIcon(isModule ? modulePercent : progress?.[lesson.id]?.percent)
      ),
      color: getStepColor(isModule ? modulePercent : progress?.[lesson.id]?.percent),
      className: cx(styles.item, {
        [styles.item_nextCompleted]:
          lessons[idx + 1] && progress?.[lessons[idx + 1].id]?.status === "completed" && progress?.[lesson.id]?.status === "completed",
      }),
      children: isModule ? (
        <LessonsModule
          openGrammarPanel={setGrammar}
          progress={progress}
          lessons={lessons}
          isCompleted={isCompleted}
          key={lesson.id}
          lesson={lesson}
          intros={intros}
        />
      ) : (
        <LessonsItem isLocked={isLocked} isCompleted={isCompleted} key={lesson.id} stars={progress?.[lesson.id]?.stars} lesson={lesson} />
      ),
    };
  });

  return (
    <div className={cx(styles.lessons, { [styles.lessons_mobile]: isMobile })}>
      <Button size={isMobile ? "middle" : undefined} type={"default"} className={styles.allCourses} onClick={() => navigate("/courses")}>
        Сменить курс
      </Button>

      <div className={styles.course}>
        <div className={styles.picture} style={{ backgroundImage: `url(${STATIC_URL}/covers/${course?.picture})` }}></div>
        <div className={styles.course__right}>
          <div className={styles.course__title}>{courseTitle}</div>
          <div className={styles.course__description}>{course?.translate}</div>
          <div className={styles.course__html} dangerouslySetInnerHTML={{ __html: course?.grammar?.content || "" }}></div>

          <Progress className={styles.courseProgress} size={"small"} percent={courseProgress} showInfo={false} strokeColor={twoColors} />
        </div>
      </div>

      <Space id={"lessons"} direction={"vertical"}>
        <Timeline items={items} />
        {/*{rootLessons.map((lesson) =>*/}
        {/*  lesson.type === "module" ? (*/}
        {/*    <LessonsModule progress={progress} lessons={lessons} isCompleted={false} key={lesson.id} lesson={lesson} />*/}
        {/*  ) : (*/}
        {/*    <LessonsItem isCompleted={false} key={lesson.id} stars={progress?.[lesson.id]?.stars} lesson={lesson} />*/}
        {/*  ),*/}
        {/*)}*/}
      </Space>

      <GrammarPanel grammar={grammar} isOpen={!!grammar} toggle={setGrammar} />
    </div>
  );
};

export default Lessons;
