import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { Breadcrumb, Button, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import API from "Api";
import React, { useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import { HomeOutlined } from "@ant-design/icons";
import styles from "./LessonReview.module.scss";
import LessonReviewTask from "./LessonReviewTask";
import * as rdd from "react-device-detect";
import { useAllTasks } from "../useAllTasks";

const LessonReview = () => {
  const navigate = useNavigate();

  const { id = "", course } = useParams();

  const { data: lessons = [] } = useQuery({
    queryKey: ["lessons", course],
    queryFn: () => API.lesson.getList(course),
    select: (data) => data.filter((l) => l.type === "exercise" || !l.type),
  });

  const lessonIdx = useMemo(() => lessons?.findIndex((l) => l.id === +id) || 0, [lessons, id]);

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  useEffect(() => {
    // @ts-ignore
    rdd.isMobile = true;
  }, []);

  const {
    story: { id: storyId, title },
  } = lesson || { story: {} };

  const { data: sentences } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  useEffect(() => {
    spanRef.current?.scrollIntoView();
  }, [id]);

  const allTasks = useAllTasks(sentences);

  const spanRef = useRef<HTMLSpanElement>(null);

  return (
    <div className={cx(styles.lessonReview, { [styles.lesson__isMobile]: isMobile })}>
      <Helmet title={`Review: ${title}`} />
      <Space>
        <Breadcrumb
          items={[
            { title: <Button type={"link"} size={"small"} icon={<HomeOutlined />}></Button> },
            { title: course },
            { title: lesson.story.title },
          ]}
        />
        <Button
          disabled={!lessons[lessonIdx - 1]}
          onClick={() => navigate(`/course/${course}/lessons/review/${lessons[lessonIdx - 1].id}`)}
          type={"link"}
        >
          {"<<<"}
        </Button>
        <Button
          disabled={!lessons[lessonIdx + 1]}
          onClick={() => navigate(`/course/${course}/lessons/review/${lessons[lessonIdx + 1].id}`)}
          type={"link"}
        >
          {">>>"}
        </Button>
      </Space>

      <div className={styles.tasks} id={"tasks"}>
        <span ref={spanRef} />
        {allTasks.map((activeSentence, idx) => (
          <LessonReviewTask
            key={idx}
            idx={idx}
            // @ts-ignore
            activeSentence={activeSentence}
            lesson={lesson}
            sentences={sentences}
            storyId={storyId}
            allTasks={allTasks}
          />
        ))}
      </div>
    </div>
  );
};

export default LessonReview;
