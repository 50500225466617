import cx from "classnames";
import React from "react";
import styles from "./PageLeaf.module.scss";

const PageLeaf = ({ attributes, children, leaf, showErrors, active }: any) => {
  const { sentId, text, editable, answer, isMasked = false, bold, newLine } = leaf;

  return (
    <span
      {...attributes}
      contentEditable={editable === false ? false : undefined}
      style={editable === false ? { userSelect: "none" } : {}}
      className={cx(styles.leaf, {
        [styles.leaf_translate]: !sentId,
        [styles.leaf_wrong]: showErrors && answer === "wrong",
        [styles.leaf_red]: showErrors && answer === "red",
        [styles.leaf_form]: showErrors && answer === "lemma",
        [styles.leaf_missed]: showErrors && answer === "missed" && !text,
        [styles.leaf_masked]: isMasked,
        [styles.leaf_texted]: !!text,
        [styles.leaf_bold]: bold,
        [styles.leaf_active]: active,
        [styles.leaf_newLine]: newLine,
      })}
    >
      {children}
    </span>
  );
};

export default PageLeaf;
