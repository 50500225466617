import useDictionary from "Components/useDictionary";
import { Button, Collapse, Flex, Popover, Tabs, Typography } from "antd";
import { SoundTwoTone } from "@ant-design/icons";
import cx from "classnames";
import React, { forwardRef, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from "./PageLesson.module.scss";

const { Text } = Typography;

const PageElementAndBlock = forwardRef((props: any, ref) => {
  const {
    isActive,
    attributes,
    children,
    element: { idx, id, newLine, startTime, endTime, type, translate, original, tags = [], phrases = [], grammarLinks = [] },
    play,
    showText = true,
    showTranslateInTooltip = false,
    onClick,
  } = props;

  const [enabled, setEnabled] = useState(false);

  const dictionary = useDictionary({ tags, phrases }, enabled);

  const onPlay = () => {
    play(startTime, endTime, true);
  };

  const dictionaryItem = {
    label: "словарь",
    children: (
      <div className={styles.dict}>
        {dictionary.map((d, idx) => (
          <div key={idx} className={styles.dict__row}>
            <div style={{ display: "flex" }}>
              <Text keyboard style={{ fontSize: 18, whiteSpace: "nowrap" }}>
                {d.lemma}
              </Text>
              <Text italic> - {d.translates?.join(", ")}</Text>
            </div>

            {["VBD", "VBN"].includes(d.tag) && (
              <div>
                <Text keyboard>{d.word}</Text> - прошед. время
              </div>
            )}
            {d.positionsIdx?.some((idx) => ["VBD", "VBN"].includes(tags[idx].pos || "")) && (
              <div>
                <Text keyboard>{d.text}</Text> - прошед. время
              </div>
            )}
          </div>
        ))}
      </div>
    ),
  };

  const items = grammarLinks.length
    ? [
        {
          label: "грамматика",
          children: (
            <Tabs
              size={"small"}
              items={grammarLinks.map((gram: any) => ({
                key: `${gram.id}`,
                label: gram.grammar.title,
                children: <div dangerouslySetInnerHTML={{ __html: gram.grammar.content }} />,
              }))}
            />
          ),
        },
        dictionaryItem,
      ]
    : [dictionaryItem];

  switch (type) {
    case "sentence":
      return (
        <Popover
          onOpenChange={setEnabled}
          content={
            <div className={styles.tooltip}>
              {showText && <Text style={{ fontSize: 18, fontWeight: 500 }}>{showTranslateInTooltip ? translate : original}</Text>}
              <Flex>
                <Button onClick={onPlay} icon={<SoundTwoTone />} type={"text"} />
                <Flex align={"center"}>
                  <Collapse ghost accordion size={"small"} items={items} />
                </Flex>
              </Flex>
            </div>
          }
          trigger={"click"}
        >
          <span
            id={idx}
            className={cx(styles.sentence, {
              [styles.sentence_mobile]: isMobile,
              [styles.sentence_active]: isActive || enabled,
              [styles.sentence_newLine]: newLine,
            })}
            onClick={() => onClick?.(id)}
            {...attributes}
            ref={ref}
          >
            {children}
          </span>
        </Popover>
      );

    case "block":
      return (
        <div style={{ margin: "4px 0" }} {...attributes}>
          {children}
        </div>
      );

    default:
      return children;
  }
});

export default PageElementAndBlock;
