import { TaskComponents } from "Components/SentenceTask";
import { STATIC_URL, STORY_TASKS, TaskTypes } from "App.constants";
import cx from "classnames";
import { LessonType, SentenceType, TaskType } from "App.types";
import StoryTask from "Components/SentenceTask/StoryTask";
import { Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { replaceTemplates } from "App.helpers";
import { UserContext } from "App";
import { useNavigate, useParams } from "react-router-dom";
import * as rdd from "react-device-detect";
import styles from "./LessonReview.module.scss";
import VideoPlayer from "Components/SentenceTask/VideoPlayer";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import useSentenceAudio from "Hooks/useSentenceAudio";

const LessonReviewTask: FC<{
  idx: number;
  activeSentence: SentenceType;
  lesson: LessonType;
  sentences: SentenceType[];
  storyId: number;
  allTasks: any[];
}> = ({ allTasks, sentences, activeSentence, lesson, idx }) => {
  const [audioIndex, setAudioIndex] = useState(0);

  const { id = "", course } = useParams();

  const navigate = useNavigate();

  const user = useContext(UserContext);
  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const TaskComponent = activeSentence?.task && TaskComponents[activeSentence.task];

  const audio1 = useSentenceAudio({ sentence: activeSentence });
  const audio2 = useSentenceAudio({ sentence: activeSentence, voice: "US Woman Ruth" });
  const audios = useMemo(() => [audio1, audio2].filter((a) => a), [audio1, audio2]);

  useEffect(() => {
    audio1?.addEventListener("play", () => audio2?.pause());
    audio2?.addEventListener("play", () => audio1?.pause());

    const setNextIdx = () => setAudioIndex((prev) => (prev === 1 ? 0 : prev + 1));

    audios.forEach((audio) => {
      (audio as HTMLAudioElement).addEventListener("ended", setNextIdx);
      // (audio as HTMLAudioElement).addEventListener("pause", setNextIdx);
    });
  }, [activeSentence, audio1, audio2, audios]);

  const title = useMemo(
    () =>
      replaceTemplates(activeSentence?.description, userProps) ||
      activeSentence?.grammar?.title ||
      TaskTypes[activeSentence?.task || ""]?.desc,
    [activeSentence?.description, activeSentence?.grammar?.title, activeSentence?.task, userProps],
  );

  return (
    <div className={styles.task}>
      <div className={styles.lesson}>
        <Button
          type={"text"}
          size={"small"}
          onClick={() => {
            // @ts-ignore
            rdd.isMobile = false;
            navigate(`/course/${course}/lessons/exercise/${id}/${idx}`, { state: { allTasks } });
          }}
          className={styles.taskType}
        >
          <ExportOutlined /> {activeSentence?.task}
        </Button>

        {title && activeSentence?.task !== TaskType.Intro && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}

        <div
          className={cx(styles.content, {
            [styles.content__fullHeight]:
              lesson.story.type === "dialog" || activeSentence?.task === "grammar" || activeSentence?.linkedStoryId,
          })}
        >
          {activeSentence &&
            ((activeSentence?.task && STORY_TASKS.includes(activeSentence.task)) ||
            lesson.story.type === "dialog" ||
            (activeSentence.linkedStoryId && activeSentence.task === TaskType.Video) ? (
              <StoryTask
                activeType={activeSentence.task}
                story={activeSentence.linkedStory || lesson.story}
                lesson={lesson}
                onTaskComplete={() => ({})}
                onNext={() => ({})}
                media={activeSentence.media}
                markers={activeSentence.markers}
                autoPlay={false}
              />
            ) : (
              <TaskComponent
                audio={audios?.[audioIndex]}
                sentence={activeSentence}
                activeType={activeSentence?.task}
                onTaskComplete={() => new Promise(() => {})}
                lesson={lesson}
                setDictOpened={() => {}}
                showComments={() => {}}
                setTries={() => {}}
                onNext={() => {}}
                // showGrammar={lesson.grammar && setGrammarOpened}
                alignCenter
                showAnswer={activeSentence?.task === TaskType.SpellerListen}
                sentences={sentences}
                autoPlay={false}
                showSuccessMessage
              >
                {activeSentence?.medias.length === 1 ? (
                  <VideoPlayer
                    showCaptions={activeSentence.markers.includes("subtitles")}
                    medias={activeSentence.medias}
                    autoPlay={false}
                  />
                ) : activeSentence?.media?.includes(".mp3") ? (
                  <audio controls src={`${STATIC_URL}/media/${activeSentence.media}`}></audio>
                ) : (
                  <img
                    className={styles.childrenImage}
                    onError={(e: any) => (e.target.style.visibility = "hidden")}
                    src={`${STATIC_URL}/media/${activeSentence?.media}`}
                    // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
                    alt={""}
                  />
                )}
              </TaskComponent>
            ))}
        </div>
      </div>
    </div>
  );
};
export default LessonReviewTask;
