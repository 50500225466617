import { useMemo } from "react";
import { API_URL } from "App.constants";
import Cookies from "js-cookie";
import { SentenceType } from "App.types";

const useSentenceAudio = ({ sentence, disabled, voice }: { sentence: SentenceType | undefined; disabled?: boolean; voice?: string }) => {
  const token = Cookies.get("token");

  return useMemo(
    () =>
      sentence?.text?.trim() && !disabled
        ? new Audio(
            `${API_URL}/speech/sent?sentId=${sentence.id}&voice=${voice ?? sentence?.voice ?? "en-US-Journey-D"}&token=${token}&rate=slow`,
          )
        : undefined,
    [sentence, disabled, voice, token],
  );
};
export default useSentenceAudio;
