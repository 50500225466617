import React, { FC, Fragment, PropsWithChildren } from "react";
import { SentenceType, StoryType } from "App.types";
import { STATIC_URL } from "App.constants";
import StorySentence from "Components/StorySentence";
import { Image } from "antd";
import styles from "./Page.module.scss";

import "./Page.css";

type Props = {
  sentences?: SentenceType[];
  title?: string;
  page: string;
  source: string;
  activeWordIdx: number | undefined;
  playSentence: Function;
  activeSent: number | undefined;
  story?: StoryType;
};

const PageMobile: FC<Props & PropsWithChildren> = ({
  story,
  playSentence,
  activeWordIdx,
  activeSent,
  source,
  sentences = [],
  title,
  page,
}) => {
  return (
    <div className={styles.page}>
      <div className={styles.page__text}>
        {sentences.map((sentence, idx) => (
          <Fragment key={sentence.id}>
            <StorySentence
              storyId={story?.id}
              activeWordIdx={activeSent === sentence.id ? activeWordIdx : undefined}
              active={activeSent === sentence.id}
              play={playSentence}
              sentence={sentence}
              marginLeft={idx === 0 || sentences[idx - 1].wrap}
              withTranslate={source === "Все вместе"}
              showText={source !== "Все вместе"}
              showTranslate={source === "Перевод"}
            />
            {sentences[idx]?.wrap && <div />}
          </Fragment>
        ))}

        <Image
          className={styles.image}
          onError={(e: any) => (e.target.style.display = "none")}
          preview={false}
          src={`${STATIC_URL}/stories/${title}/images/image ${page}.jpg`}
        />
      </div>

      {/*<div className={styles.placeholder} />*/}
    </div>
  );
};

export default PageMobile;
