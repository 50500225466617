import styles from "./TaskPanel.module.scss";
import React, { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";
import { Button, Flex, FlexProps } from "antd";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import SentenceTaskHelpButton from "Components/SentenceTask/SentenceTaskHelpButton";
import { TaskType } from "App.types";
import ReportFeedback from "Components/ReportFeedback";

const TaskPanel: FC<
  Partial<FlexProps> &
    PropsWithChildren & {
      task: TaskType | undefined;
      lessonId: string | number;
      sentId: string | number | undefined;
      storyId: string | number | undefined;
      onNext?: () => any;
      setDictOpened?: Dispatch<SetStateAction<boolean>>;
      showGrammar?: Dispatch<SetStateAction<boolean>> | undefined;
      onHint?: () => any;
      isCompleted?: boolean;
      onCheck?: () => any;
      audio?: HTMLAudioElement;
    }
> = ({
  task,
  sentId,
  storyId,
  audio,
  onHint,
  setDictOpened,
  showGrammar,
  isCompleted,
  onCheck,
  onNext,
  children,
  justify = "space-between",
  lessonId,
  ...rest
}) => {
  const location = useLocation();
  const positionAbsolute = location.pathname.includes("/review/");

  return (
    <div className={cx(styles.taskPanel, [{ [styles.taskPanel_absolute]: positionAbsolute }])} {...rest}>
      <Flex justify={justify} align={"center"} className={styles.content}>
        <ReportFeedback task={task} sentId={sentId} storyId={storyId} lessonId={lessonId} />

        {isCompleted ? (
          <Button
            onClick={onNext}
            icon={<CheckOutlined />}
            className={cx(styles.btn_next, styles.completed)}
            type={"primary"}
            shape={"round"}
          >
            далее
          </Button>
        ) : children ? (
          children
        ) : onCheck ? (
          <Button type={"primary"} shape={"round"} onClick={() => onCheck()}>
            проверить
          </Button>
        ) : (
          <Button disabled icon={<CheckOutlined />} className={styles.btn_next} type={"primary"} shape={"round"}>
            далее
          </Button>
        )}
        <SentenceTaskHelpButton
          sentId={sentId}
          storyId={storyId}
          isCompleted={isCompleted}
          openDictionary={setDictOpened}
          onHintAnswer={onHint}
          audio={audio}
          showGrammar={showGrammar}
        />
      </Flex>
    </div>
  );
};

export default TaskPanel;
