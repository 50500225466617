import ReactPlayer from "react-player";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import styles from "./VideoTask.module.scss";
import { StatusType, TaskType, TranscriptType } from "App.types";
import { SentenceTaskProps } from "../SentenceTask.type";
import sentStyles from "../SentenceTask.module.scss";
import TaskPanel from "Components/TaskPanel";
import VideoPlayer from "Components/SentenceTask/VideoPlayer";
import { SkipTags, STATIC_URL } from "App.constants";
import { encodeS3URI } from "App.helpers";
import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { groupBy, orderBy } from "lodash";

import "./styles.css";

const VideoTask: FC<SentenceTaskProps> = ({
  autoPlay = true,
  onNext,
  sentence: { id, medias, media, markers, storyId, mediaStory, mediaStoryId },
  onTaskComplete,
  showGrammar,
  lesson,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [duration, setDuration] = useState<number>();

  const { data: transcripts = [] } = useQuery({
    staleTime: Infinity,
    queryKey: ["transcripts", mediaStoryId],
    enabled: !!mediaStoryId,
    queryFn: () => (mediaStoryId ? API.transcriptions.getByStoryId(mediaStoryId) : undefined),
    select: (data) => orderBy(data, "id"),
  });

  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    setStatus(StatusType.Empty);
  }, [id]);

  const url = useMemo(() => media && !/^\d+$/.test(media) && (media.includes("http") ? media : `${STATIC_URL}/media/${media}`), [media]);

  const startTime = useMemo(() => (media?.includes("start=") ? new URL(media).searchParams.get("start") : ""), [media]);

  useEffect(() => {
    if (!isPlaying && startTime) {
      playerRef?.current?.seekTo(+startTime);
    }
  }, [startTime, isPlaying]);

  const onClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      // if (activeIdx === medias.length - 1) setActiveIdx(0);
      setIsPlaying(true);
    }
  };

  const groupedTrans = useMemo<{ [key: number]: TranscriptType[] }>(() => groupBy(transcripts, "sent"), [transcripts]);

  const showSubtitles = markers.includes("subtitles");

  useEffect(() => {
    const player = playerRef.current?.getInternalPlayer();

    if (player && duration && transcripts.length && showSubtitles) {
      for (const track of player.textTracks) {
        track.mode = "hidden";
      }
      const textTrack = player.addTextTrack("captions", "English", "en");
      textTrack.mode = "showing";

      transcripts.forEach((t) => {
        const trRow = groupedTrans[t.sent ?? 0];
        const text = trRow
          .map((tr) => (tr.start === t.start && tr.text.trim() && !SkipTags.includes(tr.text.trim()) ? `<u>${tr.text}</u>` : tr.text))
          .join("")
          .trim();

        textTrack.addCue(new VTTCue(t.start, t.end, ` ${text} `));
      });
    } else if (player?.textTracks?.length) {
      player.textTracks[0].mode = "disabled";
    }
  }, [duration, transcripts, groupedTrans, showSubtitles]);

  return (
    <div className={cx(sentStyles.sentenceTask, styles.videoTask, { [styles.videoTask_mobile]: isMobile })}>
      <div className={styles.player} onClick={startTime ? undefined : onClick}>
        {url && (
          <ReactPlayer
            ref={playerRef}
            onStart={() => setIsPlaying(true)}
            playing={isPlaying}
            playsinline={true}
            onEnded={() => {
              setIsPlaying(false);
              setStatus(StatusType.Completed);

              if (status !== StatusType.Completed) {
                onTaskComplete();
              }
              startTime && playerRef?.current?.seekTo(+startTime);
            }}
            width={isMobile ? "100%" : ""}
            height={"100%"}
            url={url}
          />
        )}

        {mediaStory && (
          <ReactPlayer
            onDuration={setDuration}
            ref={playerRef}
            height={""}
            width={"100%"}
            onEnded={() => {
              setStatus(StatusType.Completed);

              if (status !== StatusType.Completed) {
                onTaskComplete();
              }
            }}
            playsinline={true}
            controls
            playing
            url={`${STATIC_URL}/stories/${encodeS3URI(mediaStory?.title)}/video.mp4`}
          />
        )}

        {!!medias.length && (
          <VideoPlayer
            showCaptions={markers.includes("subtitles")}
            medias={medias}
            autoPlay={autoPlay}
            onFinish={() => {
              setStatus(StatusType.Completed);

              if (status !== StatusType.Completed) {
                onTaskComplete();
              }
            }}
          />
        )}

        {/*<Button type={"link"} size={"large"} onClick={onClose} className={styles.btnClose} icon={<CloseOutlined size={28} />} />*/}
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.Video}
        sentId={id}
        storyId={storyId}
        showGrammar={showGrammar}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
      />
    </div>
  );
};

export default VideoTask;
