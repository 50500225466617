import { SentenceType, StoryType, TagType } from "App.types";
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { flatten, keys, shuffle } from "lodash";
import { Flex } from "antd";
import SentenceDragDrop from "./SentenceDragDrop";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import cx from "classnames";
import { arrayMove } from "../SentenceTask.helpers";
import styles from "./StoryMaskedDragDrop.module.scss";
import useMergedTags from "Components/useMergedTags";
import { isMobile } from "react-device-detect";

const StoryMaskedDragDrop: FC<
  PropsWithChildren<{
    onTaskComplete: () => void;
    sentences: SentenceType[];
    isCompleted: boolean;
    story: StoryType;
    showTranslate?: boolean;
  }>
> = ({ story, showTranslate = true, sentences, onTaskComplete, isCompleted, children }) => {
  const [dropTags, setDropTags] = useState<{ [key: string]: { text: string; draggableId: string } | undefined }>({});
  const [tagsToSelect, setTagsToSelect] = useState<TagType[]>();
  const [selectedTag, setSelectedTag] = useState<TagType>();

  const mergedSentences = useMergedTags(sentences);

  const translateInTooltip = useMemo(() => sentences.some((s) => s.tags.length >= 5), [sentences]);

  const tags = useMemo(() => {
    return flatten(mergedSentences.map((s) => s.tags.filter((t) => t.isMasked)));
  }, [mergedSentences]);

  const tagsAsObjects: { [key: string]: TagType } = useMemo(() => tags.reduce((acc, t) => ({ ...acc, [`${t.id}`]: t }), {}), [tags]);

  useEffect(() => {
    setTagsToSelect(shuffle(tags));
  }, [tags]);

  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    const tag = tags.find((t) => `${t.id}` === draggableId);

    if (source.droppableId === destination?.droppableId && source.index === destination.index) return;

    if (destination?.droppableId === "answers" && source.droppableId === "answers") {
      setTagsToSelect((prev = []) => arrayMove(prev, source.index, destination.index));

      return;
    }

    if (destination?.droppableId && tag) {
      // setWrongTags((prev) => prev.filter((s) => s.id !== +destination?.droppableId));

      if (source.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev?.splice(source.index, 1);
          return prev;
        });

        setDropTags((prev) => ({
          ...prev,
          [destination.droppableId]: { text: tag.word, draggableId },
        }));

        if (dropTags[destination.droppableId]) {
          const sent = tags.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
          sent && setTagsToSelect((prev = []) => [...prev, { ...sent }]);
        }

        return;
      }

      if (destination.droppableId === "answers") {
        setTagsToSelect((prev) => {
          prev?.splice(destination.index, 0, tag);
          return prev;
        });

        if (source.droppableId !== "answers") {
          setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined }));
        }

        return;
      }

      setDropTags((prev) => ({ ...prev, [source.droppableId]: undefined, [destination.droppableId]: { text: tag.word, draggableId } }));

      if (dropTags[destination.droppableId]) {
        const sent = tags.find((s) => `${s.id}` === dropTags[destination.droppableId]?.draggableId);
        sent && setTagsToSelect((prev = []) => [...prev, { ...sent }]);
      }
    }
  };

  useEffect(() => {
    const hasWrong = keys(dropTags).some((key) => tagsAsObjects[key]?.word !== dropTags[key]?.text);

    if (!isCompleted && !hasWrong && tagsToSelect && tagsToSelect?.length === 0) {
      onTaskComplete();
    }
  }, [dropTags, tagsAsObjects, tags.length, tagsToSelect?.length, isCompleted, onTaskComplete, tagsToSelect]);

  const onDropClick = useCallback(
    (targetId: string) => {
      if (!selectedTag) return;

      setTagsToSelect((prev = []) => {
        const filtered = prev.filter((t) => t.id !== selectedTag.id);

        if (dropTags[targetId]) {
          const tag = tags.find((s) => `${s.id}` === dropTags[targetId]?.draggableId);
          if (tag) return [...filtered, tag];
        }
        return filtered;
      });

      const prevDropZone = keys(dropTags).find((k) => dropTags[k]?.draggableId === `${selectedTag.id}`);

      setDropTags((prev) => ({
        ...prev,
        [prevDropZone || ""]: undefined,
        [targetId]: { text: selectedTag.word, draggableId: `${selectedTag.id}` },
      }));

      setSelectedTag(undefined);
    },

    [selectedTag, dropTags, tags],
  );

  return (
    <div className={cx(styles.MaskedDragDrop, { [styles.MaskedDragDrop_mobile]: isMobile })}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"answers"} direction={"horizontal"}>
          {(provided, snapshot) => (
            <Flex
              wrap={"wrap"}
              gap={10}
              className={styles.tags}
              {...provided.droppableProps}
              ref={provided.innerRef}
              // isDraggingOver={snapshot.isDraggingOver}
            >
              {tagsToSelect?.map((t, index) => (
                <Draggable key={t.id} index={index} draggableId={`${t.id}`}>
                  {(draggableProvided, snapshot) => (
                    <span
                      className={cx(styles.tag, {
                        [styles.tag__selected]: t === selectedTag,
                        [styles.tag__nonSelected]: selectedTag && t !== selectedTag,
                      })}
                      ref={draggableProvided.innerRef}
                      onClick={() => setSelectedTag((prev) => (prev === t ? undefined : t))}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {t.word}
                    </span>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>

        {children}

        <div className={styles.sentences}>
          {mergedSentences.map((s) => (
            <SentenceDragDrop
              allTags={tags}
              setSelectedTag={setSelectedTag}
              onDropClick={onDropClick}
              selectedTag={selectedTag}
              dropTags={dropTags}
              key={s.id}
              sentence={s}
              activeWordIdx={-1}
              showTranslate={showTranslate}
              wrap={s.wrap || story.type === "dialog"}
              translateInTooltip={translateInTooltip}
            />
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

export default StoryMaskedDragDrop;
