import { useQuery } from "@tanstack/react-query";
import { SentenceType, TaskType } from "App.types";
import { SkipTags } from "App.constants";
import API from "Api";

export const useTranscripts = ({
  sentence,
  speechRate,
  audioIndex = 0,
  voice = !audioIndex ? sentence?.voice || "en-US-Journey-D" : "US Woman Ruth",
}: {
  sentence: SentenceType | undefined;
  audioIndex?: number;
  speechRate?: string;
  voice?: string;
}) => {
  const { data } = useQuery({
    enabled: !!sentence?.text?.trim() && sentence.task !== TaskType.Grammar,
    queryKey: ["transcript", sentence?.id, voice, speechRate || "slow"],
    queryFn: () => (sentence ? API.speech.getJson(sentence?.id, speechRate || "slow", voice) : undefined),
    staleTime: Infinity,
    select: (data) => data?.filter((tr) => !SkipTags.includes(tr.text)),
  });

  return data;
};
