import { Transition } from "react-transition-group";
import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";

const duration = 600;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: any = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const AnimateFade: FC<{ order?: number; hide?: boolean } & PropsWithChildren> = ({ hide = false, order = 0, children }) => {
  const ref = useRef(null);
  const [inState, setInState] = useState(!hide && !order);

  useEffect(() => {
    setInState(!hide);
  }, [hide]);

  return (
    <Transition nodeRef={ref} in={inState} timeout={order * 300}>
      {(state) => (
        <span
          ref={ref}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </span>
      )}
    </Transition>
  );
};

export default AnimateFade;
