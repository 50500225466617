import { useEffect } from "react";

export const usePressEnter = ({ isCompleted, onCheck, onNext }: { onCheck?: Function; onNext?: Function; isCompleted: boolean }) => {
  // press enter
  useEffect(() => {
    const fn = (e: KeyboardEvent) => {
      // @ts-ignore
      if (e.target?.name === "comment") return;
      if (e.key === "Enter" && isCompleted && onNext) {
        e.preventDefault();
        e.stopPropagation();
        return onNext();
      }
      if (e.key === "Enter" && !isCompleted && onCheck) {
        e.preventDefault();
        e.stopPropagation();
        onCheck().then();
      }
    };
    window.addEventListener("keydown", fn);

    return () => {
      window.removeEventListener("keydown", fn);
    };
  }, [onNext, isCompleted, onCheck]);
};
