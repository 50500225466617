import ReactPlayer from "react-player";
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import styles from "./VideoPlayer.module.scss";
import { OnProgressProps } from "react-player/base";
import { round } from "lodash";
import { STATIC_URL } from "App.constants";
import { Skeleton } from "antd";
import { PlaySquareOutlined } from "@ant-design/icons";
import { MediaType } from "App.types";
import { encodeS3URI } from "App.helpers";

type Props = {
  text?: string;
  isActive: boolean;
  clip: Partial<MediaType>;
  onFinish: Function;
  autoPlay?: boolean;
  isPlaying: boolean;
  withFullscreen: boolean | undefined;
  withBackground: boolean;
};

const VideoPlayerItem: FC<Props & PropsWithChildren> = ({
  withBackground,
  withFullscreen,
  text,
  isPlaying,
  autoPlay = true,
  isActive,
  clip,
  onFinish,
}) => {
  const [duration, setDuration] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);

  const playerRef = useRef<ReactPlayer>(null);

  const { start = 0, end, url = "", layout } = clip;

  useEffect(() => {
    if (duration) playerRef.current?.seekTo(start);
    //setIsPlaying(isActive && autoPlay);
  }, [isActive, start, autoPlay, duration]);

  useEffect(() => {
    if (isPlaying) playerRef.current?.seekTo(start);
  }, [isPlaying, start]);

  const onProgress = useCallback(
    (s: OnProgressProps) => {
      if (end && s.playedSeconds >= end && round(s.playedSeconds, 1) !== s.playedSeconds) {
        // playerRef?.current?.seekTo(start);
        // setIsPlaying(false);
        onFinish();
      }
    },
    [end, onFinish],
  );

  useEffect(() => {
    const player = playerRef.current?.getInternalPlayer();
    if (player && duration && text?.trim()) {
      let text1 = player.textTracks[0] || player.addTextTrack("captions", "English", "en");
      text1.mode = "showing";
      text1.addCue(new VTTCue(0, 99, text));
    } else if (player?.textTracks?.length) {
      player.textTracks[0].mode = "disabled";
    }
  }, [duration, text]);

  const containerRef = useRef<HTMLDivElement>(null);

  const onClick = () => {
    if (fullScreen) {
      setFullScreen(false);
      document.exitFullscreen && document.exitFullscreen();
    } else {
      if (layout === "vertical" && withFullscreen && isMobile && containerRef.current?.requestFullscreen)
        containerRef.current.requestFullscreen().then(() => setFullScreen(true));
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={onClick}
      style={{
        background: duration && !isMobile && withBackground ? "black" : undefined,
        display: isActive ? "block" : "none",
        height: isMobile ? "" : "100%",
        position: isActive ? "relative" : undefined,
      }}
    >
      <Skeleton loading={loading} active={loading} />
      <ReactPlayer
        progressInterval={100}
        onProgress={onProgress}
        playsinline={!(layout === "vertical" && withFullscreen && isMobile)}
        ref={playerRef}
        onDuration={setDuration}
        onReady={() => setLoading(false)}
        onError={() => setLoading(false)}
        onEnded={() => {
          if (document.exitFullscreen) document.exitFullscreen().then(() => setFullScreen(false));
          onFinish();
        }}
        playing={isPlaying && isActive}
        width={isMobile ? "100%" : ""}
        height={isMobile ? (fullScreen || (clip.layout === "vertical" && !withFullscreen) ? "100%" : 240) : 360}
        controls={false}
        url={url.includes("http") ? url : `${STATIC_URL}/media/${encodeS3URI(url)}`}
      />
      {!isPlaying && isActive && <PlaySquareOutlined className={styles.btnPlay} />}
    </div>
  );
};

export default VideoPlayerItem;
