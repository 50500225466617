import React from "react";
import { Card, Col, Breadcrumb, Badge, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import API from "Api";
import { useQuery } from "@tanstack/react-query";
import { Levels, STATIC_URL } from "App.constants";
import styles from "./Books.module.scss";

const Books = () => {
  const { data: books } = useQuery({ queryKey: ["books"], queryFn: () => API.story.getList("book"), staleTime: 60 * 60 * 1000 });

  const navigate = useNavigate();

  return (
    <div className={styles.books}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/books")}>Books</Breadcrumb.Item>
      </Breadcrumb>

      <Row gutter={{ xs: 12, sm: 24, md: 36, lg: 36 }}>
        {books?.map(({ title, translate, level = 0, author, id }) => (
          <Col key={id} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            <Badge.Ribbon text={Levels[level].title} color={Levels[level].color}>
              <Card
                className={styles.card}
                onClick={() => navigate(`${title}`)}
                hoverable
                cover={
                  <img
                    onError={(e: any) => (e.target.style.display = "none")}
                    alt="example"
                    src={`${STATIC_URL}/stories/${title}/images/cover.jpg`}
                  />
                }
              >
                <Card.Meta
                  title={title}
                  description={
                    <>
                      <div>
                        <Typography.Text>{translate}</Typography.Text>
                      </div>
                      {author}
                    </>
                  }
                />
              </Card>
            </Badge.Ribbon>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Books;
