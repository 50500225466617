import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { Dispatch, FC, PropsWithChildren, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { CustomText, LessonType, SentenceType, StatusType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button } from "antd";
import cx from "classnames";
import { getCompletedSlate, getInitialMaskedSlate, withCustomLogic } from "App.helpers";
import { CheckOutlined, CloseOutlined, SoundFilled } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import { useOnCheck } from "./Helpers/useOnCheck";
import { usePressEnter } from "./Helpers/usePressEnter";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import { useTranscripts } from "Hooks/useTranscripts";
import styles from "./SpeechLessonTask.module.scss";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type Props = PropsWithChildren & {
  sentence: SentenceType;
  onTaskComplete: (sentId: number) => any;
  lesson: LessonType;
  setTries?: Dispatch<SetStateAction<number>>;
  audio?: HTMLAudioElement;
  alignCenter?: boolean;
  sentences?: SentenceType[];
  active: boolean;
  reset: Function;
  activeSpeechIdx?: number;
  completedTagIdx: number;
  isCompleted: boolean;
  isRecording: boolean;
};

const SpeechLessonTask: FC<Props> = ({
  active,
  sentence,
  sentence: { id, text, tags, translate, alternatives, task: activeType = TaskType.Listen },
  lesson,
  onTaskComplete,
  children,
  audio,
  alignCenter,
  reset,
  isCompleted,
  completedTagIdx,
  isRecording,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(
    (withFocus: boolean = false) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      if (isCompleted || [TaskType.Read, TaskType.ReadByWord, TaskType.Listen, TaskType.VoiceTranslate].includes(activeType)) {
        Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
      } else if ([TaskType.Masked, TaskType.Select].includes(activeType)) {
        Transforms.insertNodes(editor, [{ children: getInitialMaskedSlate(tags, text) }]);
      } else {
        Transforms.insertNodes(editor, initialValue, { select: withFocus });
      }
    },
    [editor, isCompleted, activeType, tags, text],
  );

  useEffect(() => {
    if (active) {
      try {
        ReactEditor.focus(editor);
      } catch (e) {}
    }
  }, [active, editor]);

  // initial
  useEffect(() => {
    if (active) {
      setActiveLeaf(null);
      setStatus(StatusType.Editing);
    }
    setInitialState(active);
  }, [sentence.id, editor, setInitialState, active]);

  const transcripts = useTranscripts({ sentence, speechRate: lesson.speechRate });

  // audio transcript
  useAudioTranscript({ transcripts, setActiveLeaf, sentence, audio });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        placeholder={[TaskType.VoiceTranslate, TaskType.Repeat].includes(activeType)}
        active={active && isRecording && !isCompleted && activeType === TaskType.ReadByWord && props.leaf.idx > completedTagIdx}
        hidden={
          !isCompleted &&
          activeType === TaskType.VoiceTranslate &&
          ((!isCompleted && !active) || (active && props.leaf.idx > completedTagIdx))
        }
        underlined={props.leaf.audioIdx === activeLeaf}
        showErrors={!isCompleted}
        {...props}
      />
    ),
    [activeType, isRecording, completedTagIdx, isCompleted, active, activeLeaf],
  );

  const onCheck = useOnCheck({
    editor,
    activeType,
    text,
    tags,
    id,
    lesson,
    alternatives,
    onComplete: () => {
      setInitialState();
      Transforms.insertText(editor, text);
      setStatus(StatusType.Completed);
      onTaskComplete(sentence.id);
    },
    setStatus,
  });

  usePressEnter({ isCompleted, onCheck: active ? onCheck : undefined });

  const onReset = () => {
    reset();
    setInitialState(status === StatusType.Editing);
    ReactEditor.focus(editor);
  };

  const onKeyDown = useCallback(() => {
    setStatus(StatusType.Editing);
    reset();
  }, [reset]);

  // console.log(text, editor.children[0]);

  return (
    <div className={cx(styles.speechLessonTask, { [styles.speechLessonTask__inActive]: !active })}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={isCompleted || !active || ![TaskType.Translate].includes(activeType)}
                  onKeyDown={onKeyDown}
                  renderLeaf={renderLeaf}
                />
              </Slate>
            </form>
            {!isCompleted &&
              active &&
              (activeType === TaskType.Translate ? (
                <Button
                  size={"small"}
                  type={"text"}
                  className={styles.btn__clear}
                  icon={<CloseOutlined style={{ fontSize: 12 }} />}
                  onClick={onReset}
                />
              ) : (
                <Button
                  className={styles.btn__clear}
                  type={"text"}
                  icon={<SoundFilled />}
                  size={"small"}
                  onClick={() => {
                    audio?.play();
                    // if (activeType === TaskType.Listen) onTaskComplete(sentence.id);
                  }}
                  style={{ visibility: activeType === TaskType.VoiceTranslate ? "hidden" : "visible" }}
                />
              ))}

            {isCompleted && active && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CheckOutlined style={{ fontSize: 28, color: "green" }} />}
              />
            )}
          </div>
          <div className={styles.translate}>{translate}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SpeechLessonTask);
