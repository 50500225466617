import React, { useContext, useMemo, useState } from "react";
import { API_URL, GERMAN_VOICES } from "App.constants";
import { Button, List } from "antd";
import { PlaySquareOutlined } from "@ant-design/icons";
import { isEqualEnglishWords } from "App.helpers";
import styles from "./Dictionary.module.scss";
import { UserContext } from "App";

const DictionaryItem = ({ item, storyId }: any) => {
  const user = useContext(UserContext);
  const [clicked1, setClicked1] = useState("");
  const [clicked2, setClicked2] = useState("");

  const audio1 = useMemo(() => {
    const audio = new Audio(
      clicked1 &&
        (user?.isEnglish
          ? `${API_URL}/speech/dict?text=${item.word}&lang=uk&story=${storyId}`
          : `${API_URL}/speech/nonenglish?text=${item.word}&voice=${GERMAN_VOICES[0]}&story=${storyId}`),
    );
    audio.onerror = () => setClicked1("");
    audio.play();
    return audio;
  }, [item.word, storyId, user?.isEnglish, clicked1]);

  const audio2 = useMemo(() => {
    const audio = new Audio(
      clicked2 &&
        (user?.isEnglish
          ? `${API_URL}/speech/dict?text=${item.word}&lang=us&story=${storyId}`
          : `${API_URL}/speech/nonenglish?text=${item.word}&voice=${GERMAN_VOICES[1]}&story=${storyId}`),
    );
    audio.play();
    audio.onerror = () => setClicked2("");
    return audio;
  }, [item.word, storyId, user?.isEnglish, clicked2]);

  return (
    <List.Item>
      <List.Item.Meta
        title={
          <>
            <span className={styles.dictionary__word}>{item.word}</span>
            <Button
              onClick={() => (clicked1 ? audio1.play() : setClicked1("true"))}
              size={"small"}
              type={"text"}
              icon={<PlaySquareOutlined />}
            >
              {user?.isEnglish ? "UK" : "м."}
            </Button>
            <Button
              size={"small"}
              type={"text"}
              icon={<PlaySquareOutlined />}
              onClick={() => (clicked2 ? audio2.play() : setClicked2("true"))}
            >
              {user?.isEnglish ? "US" : "ж."}
            </Button>
          </>
        }
        description={
          <>
            <div className={styles.dictionary__translate}>{item.translates?.join(", ")}</div>

            {(item.isPast || (["VBD", "VBN"].includes(item.tag) && !isEqualEnglishWords(item.text, item.word))) && (
              <div>
                <span className={styles.dictionary__word}>{item.text}</span> - прошед. время
              </div>
            )}
          </>
        }
      />
    </List.Item>
  );
};

export default DictionaryItem;
