import ReactPlayer from "react-player";
import React, { FC, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import styles from "./VideoTask.module.scss";
import { SentenceType, StatusType, StoryType } from "App.types";
import { flatten } from "lodash";
import { STATIC_URL } from "App.constants";

type Props = {
  story: StoryType;
  onTaskComplete: () => any;
  sentences: SentenceType[];
  status: StatusType;
  autoPlay?: boolean;
};

const VideoStoryTask: FC<Props> = ({ autoPlay, status, story, sentences, onTaskComplete }) => {
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  const [activeIdx, setActiveIdx] = useState(0);

  const playerRef = useRef<ReactPlayer>(null);

  const medias = useMemo(() => flatten(sentences.map((s) => s.medias)), [sentences]);

  const onEnd = () => {
    setActiveIdx((prev) => {
      if (prev === medias.length - 1) {
        if (status !== StatusType.Completed) onTaskComplete();
        setIsPlaying(false);
        return prev;
      }

      return prev + 1;
    });
  };

  const onClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      if (activeIdx === medias.length - 1) setActiveIdx(0);
      setIsPlaying(true);
    }
  };

  return (
    <div className={cx(styles.videoTask, { [styles.videoTask_mobile]: isMobile })}>
      <div className={styles.player} onClick={onClick}>
        {medias.map((clip, idx) => (
          <ReactPlayer
            style={{ display: activeIdx === idx ? "block" : "none" }}
            key={idx}
            onSeek={() => setIsPlaying(true)}
            ref={playerRef}
            playsinline
            //onPlay={onClick}
            onError={() => setIsPlaying(false)}
            onEnded={onEnd}
            //onPause={console.log}
            playing={isPlaying && activeIdx === idx}
            width={isMobile ? "100%" : ""}
            height={story?.description?.includes("short") ? "100%" : ""}
            controls={false}
            //config={{ embedOptions: { frameborder: "0" }, playerVars: { frameborder: "0" } }}
            //url={urls[activeIdx]}
            url={`${STATIC_URL}/media/${clip.url}`}
          />
        ))}
        {/*<Button type={"link"} size={"large"} onClick={onClose} className={styles.btnClose} icon={<CloseOutlined size={28} />} />*/}
      </div>
    </div>
  );
};

export default VideoStoryTask;
