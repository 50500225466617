import { SentenceType, TranscriptType } from "App.types";
import React, { FC, useMemo, useState } from "react";
import { Button } from "antd";
import { InteractionOutlined, SoundOutlined } from "@ant-design/icons";
import cx from "classnames";
import { flatten } from "lodash";
import styles from "./VideoSentence.module.scss";
import WordWithTooltip from "Components/WordWithTooltip";

const VideoSentence: FC<{
  idx: number;
  transcripts: TranscriptType[] | undefined;
  sentence: SentenceType;
  active: boolean;
  play: Function;
  wrap: boolean;
  activeWordIdx?: number | null;
  onClick: (idx: number) => any;
  showHovered?: boolean;
  withTranslate?: boolean;
  showTranslate?: boolean;
  inActive?: boolean;
}> = ({
  idx,
  transcripts = [],
  wrap,
  active,
  play,
  activeWordIdx,
  onClick,
  showHovered,
  withTranslate = false,
  showTranslate: showTranslateDefault = false,
  inActive = false,
  sentence: { translate, tags = [], text, phrases = [] },
}) => {
  const [selectedWords, setSelectedWords] = useState<number[]>([]);
  const [showTranslate, setShowTranslate] = useState(showTranslateDefault);

  const onPlay = () => {
    play(transcripts[0].start);
  };

  const items = useMemo(() => {
    let index = 0;

    return flatten(
      tags.map((t, idx) => [
        { word: idx === 0 || text.includes(`${tags[idx - 1].word}${t.word}`) ? "" : " " },
        {
          ...t,
          idx,
          wordIdx: idx === 0 || text.includes(`${tags[idx - 1].word}${t.word}`) ? index : ++index,
          phrase: phrases.find((phrase) => phrase.positionsIdx.includes(idx)),
        },
      ]),
    ).filter((el) => el.word);
  }, [phrases, tags, text]);

  const onSpanClick = () => {
    onClick(idx);
  };

  return (
    <span
      className={cx(styles.videoSentence__text, {
        [styles.videoSentence__textWrap]: wrap,
        [styles.videoSentence__inactive]: inActive && !active,
        [styles.videoSentence__textActive]: active,
      })}
    >
      {showHovered && <Button size={"small"} type={"link"} icon={<SoundOutlined />} className={styles.btnPlay} onClick={onPlay} />}
      <span onClick={onSpanClick}>
        {showTranslate
          ? translate
          : items.map((el: any) => (
              <WordWithTooltip
                show={() => setSelectedWords(el.phrase?.positionsIdx || [el.idx])}
                hide={() => setSelectedWords([])}
                isActive={el.wordIdx === activeWordIdx || selectedWords.includes(el.idx)}
                key={el.idx}
                {...el}
                phrase={el.phrase?.text}
              />
            ))}
        {withTranslate && <span className={styles.videoSentence__translate}> {translate}</span>}
      </span>

      {showHovered && (
        <Button
          size={"small"}
          type={"link"}
          icon={<InteractionOutlined />}
          className={styles.btnSwitch}
          onClick={() => setShowTranslate((prev) => !prev)}
        />
      )}
    </span>
  );
};
export default React.memo(VideoSentence);
